import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import title from "../../assets/img/title.png";
import contactIcon from "../../assets/img/contact-icon.png";
import contactIcon1 from "../../assets/img/contact-icon1.png";
import contactIcon2 from "../../assets/img/contact-icon2.png";
import contactIcon3 from "../../assets/img/contact-icon3.png";
import address_backimg from '../../assets/img/contact/address_backimg.png';
import company_address from '../../assets/img/contact/company_address.png';
import contact_controller from '../../assets/img/contact/contact_controller.png';
import mapConfig from "../../assets/custom_map_config.json";

/**
 * 公司地址自定义覆盖物
 *
 * @authro JFJ
 * @class CompanyAddressOverlay
 * @extends {BMap.Overlay}
 */
class CompanyAddressOverlay extends BMap.Overlay {
  constructor(props) {
    super(props);
  }

  initialConfig(map, point) {
    this._map = map;
    this._point = point;
  }

  setDomStyle = (dom, keyValus) => _.forEach(keyValus, (value, key) => dom.style[key] = value)

  initialize() {
    // 创建外层容器
    const div = this.outerComponent();

    // 外容器扩展容器
    // this.outerExtraComponent();

    // 设置外容器定位
    // this.draw();

    this._map.getPanes().labelPane.appendChild(div);

    return div;
  }

  outerComponent() {
    // 外层容器
    const outerBoxDiv = this._div = document.createElement("div");
    this.setDomStyle(
      outerBoxDiv,
      {
        width: '16rem',
        height: '7rem',
        color: 'white',
        lineHeight: '1.02rem',
        textAlign: 'center',
        position: "absolute",
        whiteSpace: 'nowrap',
        MozUserSelect: 'none',
        background: `transparent url(${address_backimg}) 0% 0% / cover no-repeat`,
        zIndex: BMap.Overlay.getZIndex(this._point.lat),
      }
    );

    // 内容外容器
    const innerBox = this._span = document.createElement("span");
    outerBoxDiv.appendChild(innerBox);

    // 插入内容节点
    innerBox.appendChild(this.innerComponent());

    return outerBoxDiv;
  }

  outerExtraComponent() {
    // 创建外容器扩展容器
    const arrowBoxDiv = this._arrow = document.createElement("div");
    this.setDomStyle(
      arrowBoxDiv,
      {
        top: "0px",
        left: "0px",
        width: '16rem',
        color: 'white',
        height: '7rem',
        overflow: 'hidden',
        textAlign: 'center',
        position: "absolute",
        lineHeight: '1.02rem',
      }
    );
    this._div.appendChild(arrowBoxDiv);
  }

  innerComponent() {
    // 内层容器
    const innerBoxDiv = document.createElement("div");
    this.setDomStyle(
      innerBoxDiv,
      {
        padding: '1.5rem 2rem',
      }
    );

    // 第一行
    const firstDiv = document.createElement("div");
    this.setDomStyle(
      firstDiv,
      {
        fontSize: '1.25rem',
      }
    );
    firstDiv.appendChild(document.createTextNode('福建英迈软件有限公司'));

    // 第二行
    const secondDiv = document.createElement("div");
    secondDiv.appendChild(document.createTextNode('鼓楼科技大厦4层'));
    this.setDomStyle(
      secondDiv,
      {
        fontSize: '1.25rem',
        paddingTop: '0.55rem'
      }
    );

    innerBoxDiv.appendChild(firstDiv);
    innerBoxDiv.appendChild(secondDiv);
    return innerBoxDiv;
  }

  draw() {
    const pixel = this._map.pointToOverlayPixel(this._point);
    this._div.style.top = `calc(${pixel.y}px - 9.75rem)`;
    this._div.style.left = `calc(${pixel.x}px - 3rem)`;
  }
}

/**
 * 到这里去功能实现
 *
 * @author JFJ
 * @class GoHereController
 * @extends {BMap.Control}
 */
class GoHereController extends BMap.Control {
  constructor(props) {
    super(props);
  }

  initialConfig(map) {
    this._map = map;
    // 默认停靠位置和偏移量
    this.defaultAnchor = BMAP_ANCHOR_TOP_LEFT;
    this.defaultOffset = new BMap.Size(12, 24);
  }

  setDomStyle = (dom, keyValus) => _.forEach(keyValus, (value, key) => dom.style[key] = value)

  initialize() {
    const div = document.createElement("div");
    this.setDomStyle(
      div,
      {
        width: '10rem',
        height: '3.75rem',
        cursor: 'pointer',
        position: "absolute",
        paddingBottom: '0.5rem',
        background: `transparent url(${contact_controller}) 0% 0% / cover no-repeat`,
      }
    );

    div.onclick = (e) => {
      window.open(`http://api.map.baidu.com/marker?location=26.098903,119.310131&
        title=我的位置&content=鼓楼科技大厦&output=html&src=webapp.baidu.openAPIdemo`);
    };

    this._map.getContainer().appendChild(div);

    return div;
  }
}

class Contact extends React.Component {
  componentDidMount() {
    const map = new BMap.Map("container"); // 创建Map实例
    const point = new BMap.Point(119.286754, 26.076303);
    map.centerAndZoom(point, 16); // 初始化地图,设置中心点坐标和地图级别
    map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
    map.enableScrollWheelZoom();

    // 点标注
    const markerIcon = new BMap.Icon(company_address, new BMap.Size(28, 38));
    const marker = new BMap.Marker(point, { icon: markerIcon });
    map.addOverlay(marker);

    // 公司地址自定义标注
    const companyAddressOverlay = new CompanyAddressOverlay();
    companyAddressOverlay.initialConfig(map, point);
    map.addOverlay(companyAddressOverlay);

    // 到这里去自定义标注
    const goHereController = new GoHereController();
    goHereController.initialConfig(map);
    map.addControl(goHereController);

    // 配置个性化地图
    map.setMapStyleV2({ styleJson: mapConfig });

    // 开启鼠标滚轮缩放
    map.enableScrollWheelZoom(true);

    // 跳动的动画
    marker.setAnimation(BMAP_ANIMATION_BOUNCE);
  }


  render() {
    return (
      <div className="contact-us">
        <div className='contact-tabbar' />
        <div className="contact-en">Contact  us</div>
        <div className="contact-zh">
          联系我们
        </div>
        <div className="pic-title">
          <img src={title} alt="" />
        </div>
        <div className="contact-info">
          <p className="dot-line"></p>

          <div className="list">
            <div className="list-icon"><img src={contactIcon1} alt="" /></div>
            <div className="contactIcon"><img src={contactIcon} alt="" /></div>
            <div className="content0">联系电话</div>

            <div className="content1 tel">
              <div>0591-87880510 </div>
              <div>王女士 15959086845</div>
              <p>（周一至周五 8:30 - 17:30）</p>
            </div>
          </div>

          <div className="list">
            <div className="list-icon"><img src={contactIcon2} alt="" /></div>
            <div className="contactIcon"><img src={contactIcon} alt="" /></div>
            <div className="content0">邮箱</div>
            <div className="content1 mail">emsoft@emsoft.com.cn</div>
          </div>

          <div className="list">
            <div className="list-icon"><img src={contactIcon3} alt="" /></div>
            <div className="contactIcon"><img src={contactIcon} alt="" /></div>
            <div className="content0">总部地址</div>
            <div className="content1 addr">
              福建省福州市鼓楼区乌山西路318号鼓楼科技大厦4层
            </div>
          </div>
        </div>

        <div className="map" id="container">
        </div>

        <div className="bottom-box">
          <div className="common-box">
            <div className="block-title">了解英迈</div>
            <div className="block-content">
              英迈放眼于行业未来，一家有核心竞争力的综合性软件公司
            </div>
            <div className="block-jump">
              <Link to="/about">点击查看 »</Link>
            </div>
          </div>
          <div className="common-box">
            <div className="block-title">解决方案</div>
            <div className="block-content">
              提供金融行业和教育行业、大数据分析、软硬件一体化系统解决方案
            </div>
            <div className="block-jump">
              <Link to="/product">点击查看 »</Link>
            </div>
          </div>
          <div className="common-box">
            <div className="block-title">加入我们</div>
            <div className="block-content">
              我们正在寻找更多才华横溢的伙伴，和我们一起做充满挑战的事情
            </div>
            <div className="block-jump">
              <Link to="/recruitment">点击查看 »</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
