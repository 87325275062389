/**
 * 招贤纳士页面
 *
 * @author Jfj
 */
import React, { Fragment, useState, useEffect, useRef } from 'react';

import step_arrow_icon from '../../assets/img/recruitment/step_icon_arrow.png';
import title from '../../assets/img/title.png';
import './index.css';

class Recruitment extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='recruitment-tabbar' />

        {/* 福利待遇 */}
        <BenefitComponent />

        {/* 招聘流程 */}
        <RecruitmentFlowComponent />

        {/* 联系方式 */}
        <ContactWayComponent />

        {/* 招聘职位 */}
        <PositionListComponent />

      </Fragment>
    );
  }
}

/**
 * 福利待遇列表
 */
const iconList = [
  {
    iconUrl: require('../../assets/img/recruitment/hospital_icon.png'),
    description: "六险一金",
  },
  {
    iconUrl: require('../../assets/img/recruitment/calendar_icon.png'),
    description: "法定节假日",
  },
  {
    iconUrl: require('../../assets/img/recruitment/clock_icon.png'),
    description: "弹性工作制",
  },
  {
    iconUrl: require('../../assets/img/recruitment/gift_icon.png'),
    description: "节日福利、生日礼物、团队活动津贴、\n健康体检、季度奖、年终奖",
  },
  {
    iconUrl: require('../../assets/img/recruitment/like_icon.png'),
    description: "提供具有竞争力的薪酬，优秀者不设上限",
  },
];

const BenefitComponent = () => (
  <div className="benefits">
    <div className="benefits-text">Benefits and remuneration</div>
    <div className="benefits-wrapper">
      <img
        alt="gift"
        className="gift"
        src={require("../../assets/img/recruitment/gift.png")}
      />
      <span className="benefits-title">福利待遇及薪酬</span>
    </div>

    {iconList.map((iconItem, index) => (
      <TreatementItem key={index} idx={index + 1} {...iconItem} />
    ))}
  </div>
);

const TreatementItem = ({ idx, iconUrl, description }) => (
  <div className="item-wrapper">
    <img className={`item-icon item-icon-${idx}`} src={iconUrl} alt="" />
    <span className={`item-desc item-desc-${idx}`}>{description}</span>
  </div>
);

/**
 * 招聘流程
 */
const step_flow_list = [
  {
    imgUrl: require("../../assets/img/recruitment/step_icon_one.png"),
    description: '投简历到邮箱',
  },
  {
    imgUrl: require("../../assets/img/recruitment/step_icon_two.png"),
    description: '简历筛选',
  },
  {
    imgUrl: require("../../assets/img/recruitment/step_icon_three.png"),
    description: '一面 ',
  },
  {
    imgUrl: require("../../assets/img/recruitment/step_icon_four.png"),
    description: '二面',
  },
  {
    imgUrl: require("../../assets/img/recruitment/step_icon_five.png"),
    description: '录取通知',
    hasNextStep: false,
  },
];

const RecruitmentFlowComponent = () => (
  <div className="recruitment-wrapper">
    <div className="contact-box">
      <div className="contact-en">Contact us</div>
      <div className="contact-zh">联系我们</div>
      <div className="pic-title">
        <img src={title} alt="" />
      </div>
    </div>

    <div className="step-container">
      {
        step_flow_list.map((step, index) =>
          <RecruitmentStep key={index} idx={index + 1} {...step} />)
      }
    </div>

    <div className="recruitment-postscript">
      注：面试、录取等通知均通过短信发送，请确保您简历上的联系方式正确无误且保持通讯畅通
    </div>

  </div>
);

const RecruitmentStep = ({ idx, imgUrl, description, hasNextStep = true }) => (
  <div className="step-wrapper">
    <div className="step-box">
      <img
        alt=""
        className={`step-flow-icon step-flow-icon-${idx}`}
        src={imgUrl}
      />
      <div className="step-flow-text">{description}</div>
    </div>
    {
      hasNextStep &&
      (<div className={`step-arrow step-arrow-${idx}`}>
        <img
          alt=""
          src={step_arrow_icon}
          className="step-arrow-icon"
        />
      </div>)
    }
  </div>
);

const ContactWayComponent = () => (
  <div className="contact-container">
    <div className="contact-wrapper">
      <div className="contact-way-box">
        <div className="contact-text">Contact us</div>
        <div className="contact-way">联系方式</div>
        <img
          alt=""
          className="contact-line"
          src={require('../../assets/img/recruitment/break_line_icon.png')} />
      </div>
      <div className="contact-detail-box">
        <div className="contact">
          <span>联系人:&nbsp;&nbsp;</span>
          <span>王女士</span>
        </div>
        <div className="email">
          <span>简历投递邮箱:&nbsp;&nbsp;</span>
          <span>emsoft@emsoft.com.cn</span>
        </div>
        <div className="phone">
          <span>联系电话:&nbsp;&nbsp;</span>
          <span>0591-87880510&nbsp;&nbsp;&nbsp;15959086845</span>
        </div>
      </div>
    </div>
  </div>
);

const jobs = [
  {
    name: '团队 Leader',
    avatar: require('../../assets/img/about/team_1.png'),
    description: 'Team Leader',
    dutyList: [
      '参与公司平台架构的规划和设计',
      '核心功能的架构与代码模板编写，开发与维护系统基础核心模块',
      '参与关键组件和代码的CodeReview，评审中初级开发人员的系统设计和代码质量，编写技术文档和制定开发规范',
      '协助产品经理梳理业务需求，从技术的角度提供解决方案，并能根据方案展开研发工作',
      '带领小型团队（3~5人）对立完成平台子系统的设计、开发、项目管理工作',
    ],
    requirements: [
      '2 年以上的开发相关经验，具备一定相关项目的开发实践经验',
      '具有高度的抽象设计能力，思路清晰，善于思考，能独立分析和解决问题',
      '技术功底扎实，熟悉常用的设计模式，熟悉微服务设计，熟悉相关框架（SpringCloud 等）',
      '精通 SQL/NoSQL 数据库设计，熟悉 MongoDB、MySQL 数据库，有 SQL 性能调优经验',
      '熟悉大流量、高并发、高性能的分布式系统的设计及应用，有性能调优经验',
      '具有较强的沟通能力，责任心强，乐于接受挑战，具有良好的团队合作精神和承受压力的能力',
      '良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
    ],
  },
  {
    name: 'Java 攻城狮',
    avatar: require('../../assets/img/about/team_11.png'),
    description: 'JAVA Development Engineers',
    dutyList: [
      '负责公司中后台产品、技术支撑平台的技术设计、开发、管理工作',
      '分析并解决性能问题/瓶颈，与团队成员彼此分享技术心得与最佳实践',
      '参与公司平台架构的规划和设计、项目代码 Code Review',
      '互联网新技术的预研工作',
    ],
    requirements: [
      '1 年以上的开发相关经验，具备一定相关项目的开发实践经验',
      '热爱技术，有良好的编码风格；思路清晰，善于思考，能独立分析和解决问题',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
      '扎实的 java 编程基础， 熟悉相关开发框架（SpringMVC、SpringBoot、SpringCloud 等）',
      '熟悉 HTTP、Restful API，具有良好的接口设计与系统设计能力',
      '熟练掌握数据结构与算法、 常见设计模式，熟悉多线程编程',
      '熟悉 SQL 语言，熟悉 MySQL 或 Oracle 等数据库',
    ],
  },
  {
    name: 'C 攻城狮',
    avatar: require('../../assets/img/about/team_13.png'),
    description: 'C Development Engineers',
    dutyList: [
      '负责公司银行金融方向产品的技术设计、开发、管理工作',
      '承担核心功能模块的代码编写、性能优化及重构',
    ],
    requirements: [
      '1 年以上的开发相关经验，具有金融行业背景更佳',
      '热爱技术，有良好的编码风格；思路清晰，善于思考，能独立分析和解决问题',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心',
      '熟练掌握 C 语言编程，若额外熟悉 Java 等语言可加分；若额外熟悉 cap4j/gafe 等银行中间业务开发技术可加分',
      '熟练掌握数据结构与算法，熟悉设计模式',
      '熟练掌握 SQL 数据库语言，并熟悉 informix/mysql/sqlserver/oracle 等至少一种数据库',
      '熟练掌握 Linux 或 Windows 下的常用 shell 命令与操作',
    ],
  },
  {
    name: 'Node.js 攻城狮',
    avatar: require('../../assets/img/about/team_12.png'),
    description: 'Node.js Development Engineers',
    dutyList: [
      '负责公司产品（Web 端、APP 端、桌面端、公众号/小程序等）的中间层技术设计、开发、管理工作',
      '分析并解决性能问题/瓶颈，与团队成员彼此分享技术心得与最佳实践',
      '参与公司平台架构的规划和设计、项目代码 Code Review',
      '互联网新技术的预研和产品化工作',
    ],
    requirements: [
      '1 年以上的开发相关经验，具备一定相关项目的开发实践经验',
      '热爱技术，有良好的编码风格；思路清晰，善于思考，能独立分析和解决问题',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
      '熟练掌握 JavaScript/Node.js 语言， 熟悉相关工程化构建技术（ESLint、Babel、gulp 等）',
      '熟练掌握 ECMAScript 新特性',
      '熟悉 HTTP、Restful API、具有良好的接口设计与系统设计能力',
      '熟练掌握数据结构与算法、 常见设计模式',
      '熟悉 SQL/NoSQL 数据库设计，熟悉 MongoDB、MySQL 等数据库',
    ],
  },
  {
    name: '前端攻城狮',
    avatar: require('../../assets/img/about/team_15.png'),
    description: 'Front-End Development Engineers',
    dutyList: [
      '负责公司产品（Web 端、APP 端、桌面端、公众号/小程序等）的前端技术设计、开发、管理工',
      '分析并解决性能问题/瓶颈，与团队成员彼此分享技术心得与最佳实践',
      '参与公司平台架构的规划和设计、项目代码 Code Review',
      '互联网新技术的预研和产品化工作',
    ],
    requirements: [
      '1 年以上的开发相关经验，具备一定相关项目的开发实践经验',
      '热爱技术，有良好的编码风格；思路清晰，善于思考，能独立分析和解决问题',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
      '熟练掌握 HTML CSS JavaScript，熟悉 ECMAScript 新特性',
      '熟练掌握 React.js 或其他一门前端 MV* 框架, 如 Angular / Vue 等',
      '熟悉前端工程化构建技术（Babel、gulp、Webpack 等）',
      '熟悉 HTTP、Restful API；熟悉数据结构与算法、 常见设计模式',
    ],
  },
  {
    name: 'Python 攻城狮',
    avatar: require('../../assets/img/about/team_14.png'),
    description: 'Python Development Engineers',
    dutyList: [
      '负责公司中后台产品、大数据分析平台的技术设计、开发、管理工作',
      '分析并解决性能问题/瓶颈，与团队成员彼此分享技术心得与最佳实践',
      '参与公司平台架构的规划和设计、项目代码 Code Review',
      '互联网新技术的预研工作',
    ],
    requirements: [
      '1 年以上的开发相关经验，具备一定相关项目的开发实践经验',
      '热爱技术，有良好的编码风格；思路清晰，善于思考，能独立分析和解决问题',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
      '扎实的 Python 编程基础， 熟悉相关开发框架（Django/Flask/Tornado 等）',
      '熟悉 HTTP、Restful API，具有良好的接口设计与系统设计能力',
      '熟练掌握数据结构与算法、 常见设计模式；了解大数据分析',
      '熟悉 SQL/NoSQL 数据库设计；熟悉 Redis 或 MemCached 缓存技术',
      '熟悉ElasticSearch 或 Sonar；熟悉消息队列(MQ)，如 Kafka/Rabbitmq 等',
    ],
  },
  {
    name: '售前/售后技术支持攻城狮',
    avatar: require('../../assets/img/about/team_8.png'),
    description: 'Technical Support Engineers',
    dutyList: [
      '（售前）推广公司产品及系统解决方案，为销售提供技术支持；配合销售同客户进行技术交流',
      '（售前）引导和确认用户需求，根据用户需求编制技术解决方案；参与项目投标和合同技术谈判等',
      '（售后）负责业务系统、设备的日常运维工作',
      '（售后）负责系统基础数据的管理',
      '（售后）负责系统的培训指导工作',
    ],
    requirements: [
      '1-3年工作经验，大专以上学历，计算机、软件、通讯、网络相关专业优先',
      '工作积极、严谨、能吃苦，责任心强',
      '具有良好的团队精神和学习能力，踏实肯干；适应出差',
      '（售前）思路清晰，善于思考，熟练掌握通用办公软件并有较强的文案能力和方案讲解能力',
      '（售前）有较强的人际沟通技巧和客户亲和力，具有良好的售前支持和客户沟通能力',
      '（售前）熟练使用 Visio、Word、Excel、PowerPoint 等软件',
      '（售后）熟悉 C#、SqlServer 或 Oracle 数据库',
    ],
  },
  {
    name: 'UI 设计狮',
    avatar: require('../../assets/img/about/team_4.png'),
    description: 'UI Desiners',
    dutyList: [
      '负责公司项目的美术设计，包括网站、APP的UI、页面布局、色彩搭配',
      '负责产品视觉和交互设计，包括界面、图标等的图形界面设计，输出PSD制作、切图',
      '配合策划推广活动，制作推广相关专题页面',
      '对产品界面进行持续的设计优化，提升用户体验',
      '参与产品分析、创意，并提出建议性意见',
    ],
    requirements: [
      '本科及以上学历，美术或者设计相关专业毕业；1 年及以上从事设计相关的经验，要求能独立完成设计与制作',
      '对设计有浓厚的兴趣，优秀的审美能力；具有扎实的美术功底，对设计与色彩的把控能力强，具备新颖的设计创意',
      '良好的沟通能力和团队协作精神，良好的学习和表达能力，较强的主动性和责任心；能够自我驱动，不断学习',
      '熟悉多平台的UI设计，了解WEB端、APP端等设计规范；有多样且娴熟的综合设计能力（如交互、UI动效等）',
      '能把握视觉设计的流行趋势，充分理解UI设计中体现产品的视觉整体性，并注重细节的处理；',
      '熟练掌握相关设计工具、PS、AI等设计软件；有扎实的美术功底、对视觉风格及项目整体把控力强，',
      '表达能力好，能流畅地表达设计理念；执行能力好，能迅速产出符合产品要求的UI作品；',
    ],
  },
]

const PositionListComponent = () => (
  <div className="recruitment-wrapper position-warpper">
    <div className="contact-box">
      <div className="contact-en">Staff recruitment</div>
      <div className="contact-zh">招聘职位</div>
      <div className="pic-title">
        <img src={title} alt="" />
        <p className="line"></p>
      </div>
    </div>

    <div className="job-list">
      {
        jobs.map((job, index) => (<PositionItem idx={index} key={index} {...job} />))
      }
    </div>
  </div>
);

const PositionItem = ({ avatar, name, description, dutyList, requirements, idx: index }) => {
  const imgRef = useRef(null);

  const [scrollTop, setScrollTop] = useState(0);

  const scrollFunc = () => setScrollTop(window.document.documentElement.scrollTop);

  const imgHeight = 140;
  const [mgTop, setMgTop] = useState(imgHeight);

  useEffect(() => {
    const top = imgRef.current.getBoundingClientRect().top;
    if (top < window.innerHeight) {
      setMgTop(0);
    }
    if (top < -imgHeight) {
      setMgTop(imgHeight);
    }
  }, [scrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', scrollFunc);
    return () => window.removeEventListener('scroll', scrollFunc);
  });

  return (
    <div className="job-card" key={index}>
      <div className="avatar">
        <img ref={imgRef} style={{ marginTop: mgTop }} src={avatar} alt="" />
      </div>
      <div className="job-title">{name}</div>
      <div className="job-title-description">{description}</div>
      <div className="cut-off-line" />

      <div className="job-desc-list">
        <div className="job-desc-title">
          在 <span className="emsoft">EMSoft</span> 你将会负责：
        </div>
        <ul>
          {dutyList.map(item => (
            <li className="job-desc-item">{item}</li>
          ))}
        </ul>
      </div>

      <div className="job-desc-list">
        <div className="job-desc-title">
          <span className="emsoft">EMSoft</span> 对你的期望：
        </div>
        <ul>
          {requirements.map(item => (
            <li className="job-desc-item">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )
};

export default Recruitment;
