import React from 'react';
import { withRouter } from 'react-router-dom';
import style from '../../index.module.scss';

@withRouter
class ProgressBar extends React.Component{
  render(){
    return (
      <div
        key={this.props.location.pathname}
        className={style['progress-bar']}>
      </div>
    );
  }
}

export default ProgressBar;
