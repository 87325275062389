/**
 * @author LH
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import style from '../../../index.module.scss';
import footerBg from '@assets/img/layout/footer-bg.png';

/**
 * 所有可修改数据数据
 * @constant
 * @type {Array}
 */
const data = {
  // 基础信息（logo 子标题 电话 邮箱 地址）
  baseInfo: {
    logo: require('@assets/img/layout/footer-logo.png'),
    subTitle: '因时创变，开放互联',
    phone: '0591-87880510',
    tel: '15959086845',
    email: 'emsoft@emsoft.com.cn',
    address: '福建省福州市鼓楼区乌山西路318号鼓楼科技大厦4层',
  },
  // 关于我们数据（标题、列表项目{ 标题， 点击跳转url(url) }）
  aboutUs: {
    title: '关于我们',
    list: [
      { title: '关于英迈', url: '/about' },
      { title: '招贤纳士', url: '/recruitment' },
      { title: '联系我们', url: '/contact' },
    ]
  },
  // 解决方案数据（标题、列表项目{ 标题， 点击跳转url(url) }）
  solution: {
    title: '解决方案',
    list: [
      { title: '服刑人员综合服务一体化平台', url: '/product/details/smartPrison' },
      { title: '涉案款项监管与智慧研判平台', url: '/product/details/moneyInvolved' },
      { title: '专项资金监管云平台', url: '/product/details/liveBuilding' },
      { title: '智慧资产数字化管理平台', url: '/product/details/smartProperty' },
      { title: '银行运营风控', url: '/product/details/bankRiskConctrol' },
      { title: '教育缴费云平台', url: '/product/details/eduCloud' },
    ]
  },
  // 关注微信公众号数据（标题、二维码）
  followUs: {
    title: '关注微信公众号',
    qr: require('@assets/img/layout/qr-code.png')
  },
  // 备案信息
  filing: {
    itemNo: '闽ICP备11000019号-3',
    copyRight: ` Copyright © ${new Date().getFullYear()} EMSoft. All Rights Reserved.`
  }
};

/**
 * 关于我们、解决方案、关注微信 公用头部
 * @param {Object} props
 */
const ItemHeader = (props) => (
  <div className={style['footer-item-header']}>
    <div className={style['footer-item-header-title']}>
      {props.title}
    </div>
  </div>
);

/**
 * UL 列表
 * @extends React.Component
 */
@withRouter
class ItemUlList extends React.Component {

  // 页面跳转
  jumpTo = (data) => {
    const { history } = this.props;
    data.url && history.push(data.url);
  }

  render() {
    return (
      <ul className={style['footer-item-ul']}>
        {(this.props.list || []).map((v, index) => (
          <li
            className={`${style['footer-item-ul-li']} cp`}
            onClick={this.jumpTo.bind(null, v)}
            key={index}
          >
            {v.title}
          </li>
        ))}
      </ul>
    );
  }
}

/**
 * 基本信息
 * @param {Object} props
 */
const BaseInfo = () => (
  <div className={style['base-info']}>
    <div className={style['base-info-img']}>
      <img src={data.baseInfo.logo} alt="logo" />
    </div>
    <div className={style['base-info-sub-title']}>
      {data.baseInfo.subTitle}
    </div>
    <div className={style['base-info-phone']}>
      <p className={style['base-info-phone-label']}>联系电话</p>
      <p className={style['base-info-phone-num']}>{data.baseInfo.phone}</p>
      <p className={style['base-info-phone-tel']}>{data.baseInfo.tel}</p>
    </div>
    <p className={style['base-info-email-address']}>
      邮箱：{data.baseInfo.email} <br />
      总部地址：{data.baseInfo.address}
    </p>
  </div>
);

/**
 * 关于我们
 * @param {Object} props
 */
const AboutUs = () => (
  <div className={style['about-us']}>
    <ItemHeader title={data.aboutUs.title} />
    <ItemUlList list={data.aboutUs.list} />
  </div>
);

/**
 * 解决方案
 * @param {Object} props
 */
const Solution = () => (
  <div className={style['solution']}>
    <ItemHeader title={data.solution.title} />
    <ItemUlList list={data.solution.list} />
  </div>
);

/**
 * 关注我们
 * @param {Object} props
 */
const FollowUs = () => (
  <div className={style['follow-us']}>
    <ItemHeader title={data.followUs.title} />
    <div className={style['follow-us-qr']}>
      <img src={data.followUs.qr} alt="二维码" />
    </div>
  </div>
);

/**
 * 备案
 * @param {Object} props
 */
const Filing = () => (
  <div className={style['footer-info-top-filing']}>
    <a className={style['footer-info-top-link']}
      target='_blank'
      href='https://beian.miit.gov.cn/'>
      {data.filing.itemNo}
    </a>
    {data.filing.copyRight}
  </div>
);

export default class FooterInfo extends React.Component {
  render() {
    return (
      <div className={style['footer-info']}>
        <div className={style['footer-info-top']}>
          <div className={style['footer-info-top-bg']}>
            <img src={footerBg} alt="背景图" />
          </div>
          <div className={`${style['footer-info-top-wrapper']}`}>
            <div className={`${style['footer-info-top-wrapper-content']} clearfix`}>
              <BaseInfo />
              <AboutUs />
              <Solution />
              <FollowUs />
            </div>
          </div>
          <Filing />
        </div>
      </div>
    );
  }
}
