/**
 * @author LH
 */
import React from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';

const data = {
  title: {
    subTitle: 'Business areas',
    title: '业务领域',
    icon: 'blue',
  },
  list: [
    {
      title: '资金管理',
      content: '英迈王牌业务，在资金管理领域耕耘十多年，成功实施了二十多家银行的项目',
      img: require('@assets/img/about/business_1.png')
    }, {
      title: '教育领域',
      content: '涉及教育缴费、校园一卡通、智慧校园，下一步进军少儿编程市场（互联网尝试）',
      img: require('@assets/img/about/business_2.png')
    }, {
      title: '大数据',
      content: '点击应用大数据技术帮助用户做智能分析、协作分享，辅助决策',
      img: require('@assets/img/about/business_3.png')
    }, {
      title: '互联网+',
      content: '帮助政府企事业单位向“互联网+”转型',
      img: require('@assets/img/about/business_4.png')
    }
  ],
};

class BusinessList extends React.Component{
  render(){
    return (
      <div className={style['business']}>
        <TitleBlock {...data.title}/>
        <div className={`${style['business-list']} clearfix`}>
          {data.list.map( (v, index) => (<ItemBlock {...v} key={index}/>))}
        </div>
        <div className={style['business-more']}>
          还有更多业务待开发敬请期待
        </div>
      </div>
    );
  }
}
export default BusinessList;

class ItemBlock extends React.Component{
  render(){
    return (
      <div className={`${style['business-item']} fl`}>
        <img src={this.props.img} alt="业务图标"/>
        <div className={style['business-item-title']}>
          {this.props.title}
        </div>
        <p className={style['business-item-content']}>
          {this.props.content}
        </p>
      </div>
    );
  }
}
