import React from 'react';
import {
  Route, 
  Redirect, 
  Switch 
} from 'react-router-dom';
import settings from './settings';
import NotFound from '@pages/Error/NotFound';

export default class RouterBlock extends React.Component{
  render(){
    return (
      <Switch>
        {
          settings ? settings.map(setting => (
            <Route 
              key={setting.path} 
              path={setting.path} 
              exact={setting.exact}
              component={setting.component}
            />
          )) : null
        }
        <Route component={NotFound}/>
      </Switch>
    );
  }
}
