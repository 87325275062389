/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../../index.module.scss';
import MenuBlock from './subpage/MenuBlock';
import SwiperBlock from './subpage/SwiperBlock';

export default class Header extends React.Component{
  render(){
    return (
      <Fragment>
        <MenuBlock />
        <SwiperBlock />
      </Fragment>
    );
  }
}
