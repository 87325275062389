import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import style from './index.module.scss';

// 关于英迈 图片
import aboutEmstfLogo from '../../assets/img/home/aboutEmsoft-logo.png';
import huaweiLogo from '../../assets/img/home/huawei_logo.png';
import txSecurityLogo from '../../assets/img/home/txaq.png';
import hikvisionLogo from '../../assets/img/home/hikvision.png';
import warning from '../../assets/img/home/warning.png';
// 解决方案 图片
import titleIcon from '../../assets/img/home/title.png';
// 资质认证
import dagou from '../../assets/img/home/icon_dagou.png';
import zhengshu1 from '../../assets/img/home/zhengshu1.jpg';
import zhengshu2 from '../../assets/img/home/zhengshu2.png';
import zhengshu3 from '../../assets/img/home/zhengshu3.jpg';
// 产品介绍
import moreIcon from '../../assets/img/home/more.png';

// 业务分布 图片
import chinaMapIcon from '../../assets/img/home/china_map_icon.png';
import productList1Bac from '../../assets/img/home/fuxing_bg.png';
import productList1 from '../../assets/img/home/product_list_1.png';
import productList1Icon1 from '../../assets/img/home/product_list_1_icon_1.png';
import productList1Icon2 from '../../assets/img/home/product_list_1_icon_2.png';
import productList1Icon3 from '../../assets/img/home/product_list_1_icon_3.png';
import productList1Icon4 from '../../assets/img/home/product_list_1_icon_4.png';
import productList1Icon5 from '../../assets/img/home/product_list_1_icon_5.png';
import productList2Bac from '../../assets/img/home/shean_bg.png';
import productList2 from '../../assets/img/home/product_list_6.png';
import productList2Icon1 from '../../assets/img/home/product_list_2_icon_1.png';
import productList2Icon2 from '../../assets/img/home/product_list_2_icon_2.png';
import productList2Icon3 from '../../assets/img/home/product_list_2_icon_3.png';
import productList2Icon4 from '../../assets/img/home/product_list_2_icon_4.png';
import productList3Bac from '../../assets/img/home/zhaobiao_bg.png';
import productList3 from '../../assets/img/home/product_list_3.png';
import productList4Bac from '../../assets/img/home/zichan_bg.png';
import productList4 from '../../assets/img/home/product_list_4.png';
import productList5Bac from '../../assets/img/home/zhuwei_bg.png';
import productList5 from '../../assets/img/home/product_list_5.png';
import productList6Bac from '../../assets/img/home/jiaoyu_bg.png';
import productList6 from '../../assets/img/home/product_list_2.png';

// 为何选择我们 图片
import titleIcon2 from '../../assets/img/home/title_icon_2.png';
import chooceUsPic1 from '../../assets/img/home/choose_us_1.png';
import chooceUsPic1Selet from '../../assets/img/home/choose_us_1_selected.png';
import chooceUsPic2 from '../../assets/img/home/choose_us_2.png';
import chooceUsPic2Selet from '../../assets/img/home/choose_us_2_selected.png';
import chooceUsPic3 from '../../assets/img/home/choose_us_3.png';
import chooceUsPic3Selet from '../../assets/img/home/choose_us_3_selected.png';
import chooceUsPic4 from '../../assets/img/home/choose_us_4.png';
import chooceUsPic4Selet from '../../assets/img/home/choose_us_4_selected.png';
import chooceUsPic5 from '../../assets/img/home/choose_us_5.png';
import chooceUsPic5Selet from '../../assets/img/home/choose_us_5_selected.png';

// 合作伙伴 图片
import partners1 from '../../assets/img/home/bank_zgyh.png';
import partners2 from '../../assets/img/home/bank_gsyh.png';
import partners3 from '../../assets/img/home/bank_nyyh.png';
import partners4 from '../../assets/img/home/bank_jsyh.png';
import partners5 from '../../assets/img/home/bank_xyyh.png';
import partners6 from '../../assets/img/home/bank_jtyh.png';
import partners7 from '../../assets/img/home/bank_hxyh.png';
import partners8 from '../../assets/img/home/bank_qzyh.png';
import partners9 from '../../assets/img/home/huaweiyun.png';
import partners10 from '../../assets/img/home/aliyun.png';
import partners11 from '../../assets/img/home/txyun.png';
import partners12 from '../../assets/img/home/gxyun.png';


// 联系我们 图片
import contactUsPhone from '../../assets/img/home/contact__us_phone.png';
import contactUsAddrss from '../../assets/img/home/contact_us_addrss.png';
import contactUsEmail from '../../assets/img/home/contact_us_eMail.png';
import contactUsIcon from '../../assets/img/home/contact_us_icon.png';

/**
 * @author kjx
 */
class HomePage extends React.Component {
  render() {
    return (
      <Fragment>
        {/* 关于英迈 */}
        <AboutEmSoft />

        {/* 企业文宣 */}
        <Description />

        {/* 解决方案 */}
        <IndustrySolutions />

        {/* 产品介绍 */}
        <ProductIntroduction />

        {/* 业务分布 */}
        <DistributionOfBusinese />

        {/* 为何选择我们 */}
        <ChooceUs />

        {/* 资产认证 */}
        <Qualification />

        {/* 合作伙伴 */}
        <Partners />

        {/* 联系我们 */}
        <ContactUs />
      </Fragment>
    );
  }
}

export default HomePage;

//关于英迈
class AboutEmSoft extends React.Component {
  render() {
    return (
      <div className={style['home_about_emsoft']}>
        <article>
          <div>
            <img src={warning} alt="Waring" />
            <h3>About Emsoft</h3>
            <h2>关于英迈</h2>
          </div>
          <img
            className={style['about_emsoft_logo']}
            src={aboutEmstfLogo}
            alt="关于英迈Logo" />
          <p>
            英迈软件成立自2010年，<br />
            通过跨界、融合，与伙伴联合打造深入场景的数字生态平台，<br />
            为行业提供持续的增长价值，产品线涵盖司法、金融、政务、教育等领域。
          </p>
          <img
            className={style['about_partner1_logo']}
            src={huaweiLogo}
            alt="华为" />
          <img
            className={style['about_partner2_logo']}
            src={hikvisionLogo}
            alt="海康威视" />
          <br />
          <span>华为认证解决方案开发伙伴(ISV/IHV)</span>
          <span>海康威视战略合作伙伴</span>
          <p>
            2021年，英迈与华为正式发布政法场景领域联合方案，<br />
            作为华为认证级解决方案开发伙伴，基于鸿蒙软硬件生态，赋能公检法司机关并建立深度合作。
          </p>
        </article>
      </div>
    );
  }
}

//企业文宣
class Description extends React.Component {
  render() {
    return (
      <div className={style['home_description']}>
        {
          this.data.map((item, idx) => {
            return (
              <section key={idx}>
                <span className={`iconfont ${item.icon}`} style={item.iconStyle}></span>
                <p className={style['home_description_name']}>{item.name}</p>
                <p className={style['home_description_nameEnglish']}>{item.nameEnglish}</p>
              </section>
            );
          })
        }
      </div>
    );
  }

  get data() {
    const data = [{
      icon: 'iconchuangxin',
      name: '创新',
      nameEnglish: 'innovation',
      iconStyle: {
        fontSize: 48,
      }
    }, {
      icon: 'iconzhuanzhu',
      name: '专注',
      nameEnglish: 'focus',
      iconStyle: {
        fontSize: 48,
      }
    }, {
      icon: 'icongaoxiao',
      name: '高效',
      nameEnglish: 'efficient',
      iconStyle: {
        fontSize: 48,
      }
    }, {
      icon: 'iconchengxin',
      name: '诚信',
      nameEnglish: 'integrity',
      iconStyle: {
        fontSize: 42,
      }
    }, {
      icon: 'icongongying',
      name: '共赢',
      nameEnglish: 'win-win',
      iconStyle: {
        fontSize: 42,
      }
    }];
    return data;
  }
}

// 解决方案
class IndustrySolutions extends React.Component {
  render() {
    return (
      <div className={style['home_industry_solutions']}>
        <div className={style['home_industry_solutions_title']}>
          <h2>Industry solutions</h2>
          <h3>行业解决方案</h3>
          <img src={titleIcon} alt="标题" />
          <p>
            依托领先的闭环场景化服务能力，提供数字赋能服务及整体解决方案。
          </p>
        </div>
        <article className="clearfix">
          <section>
            <aside className={style['home_industry_solutions_internet']}>
              <span className="iconfont iconhulianwangzhuanchang"></span>
              <p>互联网+、移动</p>
            </aside>
            <p>自身研发互联网产品，并利用互联网和移动互联技术帮助客户拥抱互联网，
              为客户植入互联网基因。</p>
          </section>
          <section>
            <aside className={style['home_industry_solutions_AI']}>
              <span className="iconfont iconAI"></span>
              <p>AI</p>
            </aside>
            <p>利用AI技术结合各应用场景提供软硬件一体化系统解决方案。</p>
          </section>
          <section>
            <aside className={style['home_industry_solutions_data']}>
              <span className="iconfont icondashujuzhichi"></span>
              <p>大数据</p>
            </aside>
            <p>给予大数据针对应用场景提供数据分析、决策辅助服务。</p>
          </section>
          <section>
            <aside className={style['home_industry_solutions_cloud']}>
              <span className="iconfont iconyunfuwu"></span>
              <p>云服务</p>
            </aside>
            <p>提供金融行业和教育行业PasS和SaaS层云服务。</p>
          </section>
        </article>
      </div>
    );
  }
}

// 产品介绍
class ProductIntroduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: 1,
      menu: ['服刑人员综合服务一体化平台', '涉案款项监管与智慧研判平台', '专项资金监管云平台',
        '智慧资产数字化管理平台', '银行运营风控', '教育缴费云平台'],
      product: [
        {
          className: 'fuxing',
          imgSrc: productList1,
          imgBac: productList1Bac,
          imgAlt: '服刑人员综合服务一体化平台',
          title: '服刑人员综合服务一体化平台',
          content: '实现服刑人员“入监、进账、消费、教育、离监”等环节，全链条全过程精细化管理。',
          buttonName: '点击查看',
          href: '/product/details/smartPrison',
          flows: [
            { imgSrc: productList1Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList1Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList1Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList1Icon4, imgAlt: '', title: '缴费方式人性化' },
            { imgSrc: productList1Icon5, imgAlt: '', title: '使用场景丰富' },
          ],
        }, {
          className: 'jiaoyu',
          imgSrc: productList2,
          imgBac: productList2Bac,
          imgAlt: '涉案款项监管与智慧研判平台',
          title: '涉案款项监管与智慧研判平台',
          content: '面向纪委、公检法等机关，构建涉案款项等全域监管闭环',
          buttonName: '点击查看',
          href: '/product/details/moneyInvolved',
          flows: [
            { imgSrc: productList1Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList1Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList1Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList1Icon4, imgAlt: '', title: '缴费方式人性化' },
            { imgSrc: productList1Icon5, imgAlt: '', title: '使用场景丰富' },
          ],
        }, {
          className: 'xiaoyuan',
          imgSrc: productList3,
          imgBac: productList3Bac,
          imgAlt: '专项资金监管云平台',
          title: '专项资金监管云平台',
          content: '政府监管，专款专用，面向住建预售款、公维金、农民工工资&保证金、公共资源交易等场景，已服务数十家企事业单位。',
          buttonName: '点击查看',
          href: '/product/details/liveBuilding',
          flows: [
            { imgSrc: productList1Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList1Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList1Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList1Icon4, imgAlt: '', title: '缴费方式人性化' },
            { imgSrc: productList1Icon5, imgAlt: '', title: '使用场景丰富' },
          ],
        }, {
          className: 'hulian',
          imgSrc: productList4,
          imgBac: productList4Bac,
          imgAlt: '智慧资产数字化管理平台',
          title: '智慧资产数字化管理平台',
          content: '将租赁、物业、财务等工作相融合，建立标准的自动化管理体系，对合同、资产进行全方面管理。',
          buttonName: '点击查看',
          href: '/product/details/smartProperty',
          flows: [
            { imgSrc: productList1Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList1Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList1Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList1Icon4, imgAlt: '', title: '缴费方式人性化' },
            { imgSrc: productList1Icon5, imgAlt: '', title: '使用场景丰富' },
          ],
        }, {
          className: 'zhaobiao',
          imgSrc: productList5,
          imgBac: productList5Bac,
          imgAlt: '银行运营风控',
          title: '银行运营风控',
          content: '整合银行内外部跨平台数据，构建数据治理体系，快速甄别运营风险，助力运营合规与优化。',
          buttonName: '点击查看',
          href: '/product/details/bankRiskConctrol',
          flows: [
            { imgSrc: productList1Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList1Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList1Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList1Icon4, imgAlt: '', title: '缴费方式人性化' },
            { imgSrc: productList1Icon5, imgAlt: '', title: '使用场景丰富' },
          ],
        }, {
          className: 'fuxing',
          imgSrc: productList6,
          imgBac: productList6Bac,
          imgAlt: '教育缴费云平台',
          title: '教育缴费云平台',
          content: '实现学校各类收费的支付圈过程统一管理，实现多渠道收款，财务账电子化，提升校园信息化水平。',
          buttonName: '点击查看',
          href: '/product/details/eduCloud',
          flows: [
            { imgSrc: productList2Icon1, imgAlt: '', title: '报名流程可选' },
            { imgSrc: productList2Icon2, imgAlt: '', title: '财务管理一体化' },
            { imgSrc: productList2Icon3, imgAlt: '', title: '收款渠道多样化' },
            { imgSrc: productList2Icon4, imgAlt: '', title: '缴费方式人性化' },
          ],
        }],
    }
  }

  changeState(name, val) {
    this.setState({
      [name]: val
    });
  }

  render() {
    const { menu, idx, product } = this.state;
    return (
      <div className={style['home_product']}>
        <div className={style['home_product_header']}>
          <h2>Product introduction</h2>
          <h3>产品介绍</h3>
          <img src={titleIcon} alt="产品介绍" />
          <p>
            英迈产品线涵盖司法、金融、政务、教育等领域，通过跨界、融合，与伙伴联合打造深入场景的数字生态平台。
          </p>
        </div>
        <div className={style['home_product_content']}>
          <div className={style['home_product_menu']}>
            <ul>
              {menu.map((item, index) => {
                const finger = index + 1;
                return (
                  <li key={index} onClick={this.changeState.bind(this, 'idx', finger)}
                    className={finger === idx ? style['selected'] : ''}>
                    {item}
                  </li>);
              })}
            </ul>
          </div>
          <div className={style['home_product_list']}>
            <ul>
              {product.map((item, index) => {
                const { flows = [] } = item;
                return (
                  <li style={{ display: index + 1 === idx ? 'block' : 'none' }}
                    key={index} className={style[item.className]}>
                    <img src={item.imgBac} alt={`${item.imgAlt}背景图`} />
                    <div className={style['home_product_content']}>
                      <div className={style['home_product_message']}>
                        <div className={style['product_message_img']}>
                          <img src={item.imgSrc} alt={item.imgAlt} />
                        </div>
                        <div className={style['product_message_intro']}>
                          <h2>{item.title}</h2>
                          <p>{item.content}</p>
                          <Link to={item.href || '#'}>
                            {item.buttonName || '点击查看'}
                          </Link>
                        </div>
                      </div>
                      {/* <div className={style['home_product_flow']}>
                        <ol>
                          {
                            flows.map((flow, flowIndex) => {
                              return (
                                <li key={flowIndex}>
                                  <img src={flow.imgSrc} alt={flow.imgAlt || flow.title} />
                                  <p>{flow.title}</p>
                                </li>
                              );
                            })
                          }
                        </ol>
                      </div> */}
                    </div>
                  </li>
                );
              })}

            </ul>
          </div>
        </div>
        <div className={style['home_product_footer']}>
          <img src={moreIcon} alt="更多" />
          <a href="#">更多产品点击查看</a>
        </div>
      </div>
    );
  }
}

// 业务分部
class DistributionOfBusinese extends React.Component {
  render() {
    return (
      <div className={style['home_businese']}>
        <div className={style['home_businese_header']}>
          <h2>Distribution of businese</h2>
          <h3>业务分布</h3>
          <img src={titleIcon} alt="标题" />
          <p>
            与国内数十家政企事业单位、金融机构、 IT 公司、第三方支付公司建立合作关系。
          </p>
        </div>
        <div className={style['home_businese_content']}>
          <ul>
            <li className={style['wulumuqi']}>
              <div>
                <span>乌鲁木齐</span>
                <img src={chinaMapIcon} alt="乌鲁木齐" />
                <i></i>
              </div>
            </li>
            <li className={style['taiyuan']}>
              <div>
                <span>太原</span>
                <img src={chinaMapIcon} alt="太原" />
                <i></i>
              </div>
            </li>
            <li className={style['beijing']}>
              <div>
                <span>北京</span>
                <img src={chinaMapIcon} alt="北京" />
                <i></i>
              </div>
            </li>
            <li className={style['changchun']}>
              <div>
                <span>长春</span>
                <img src={chinaMapIcon} alt="长春" />
                <i></i>
              </div>
            </li>
            <li className={style['wuhan']}>
              <div>
                <span>武汉</span>
                <img src={chinaMapIcon} alt="武汉" />
                <i></i>
              </div>
            </li>
            <li className={style['changsha']}>
              <div>
                <span>长沙</span>
                <img src={chinaMapIcon} alt="长沙" />
                <i></i>
              </div>
            </li>
            <li className={style['shanghai']}>
              <div>
                <span>上海</span>
                <img src={chinaMapIcon} alt="上海" />
                <i></i>
              </div>
            </li>
            <li className={style['fuzhou']}>
              <div>
                <span>福州</span>
                <img src={chinaMapIcon} alt="福州" />
                <i></i>
              </div>
            </li>
          </ul>
        </div>
        <div className={`${style['home_businese_footer']} clearfix`}>
          <span>省外</span>
          <ul>
            <li>北京</li>
            <li>上海</li>
            <li>太原</li>
            <li>长春</li>
            <li>武汉</li>
            <li>长沙</li>
            <li>乌鲁木齐</li>
          </ul>
          <i></i>
          <span>省内</span>
          <ul>
            <li>福州</li>
            <li>泉州</li>
            <li>宁德</li>
            <li>三明</li>
            <li>龙岩</li>
            <li>漳州</li>
            <li>莆田</li>
            <li>南平</li>
          </ul>
        </div>
      </div>
    )
  }
}

// 为何选择我们
class ChooceUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: 3,
      time: 3000,
      data: [{
        img: chooceUsPic1,
        selectedImg: chooceUsPic1Selet,
        title: '王牌专业',
        titleEnglish: 'Ace professional',
        content: '英迈除了输出自身的互联网产品外，还输出互联网技术支持， 帮助客户植入互联网基因。',
      }, {
        img: chooceUsPic2,
        selectedImg: chooceUsPic2Selet,
        title: '团队力量',
        titleEnglish: 'Team strength',
        content: '英迈拥有一支年轻、有活力、专注的核心团队， 团队富有创造力助力公司提供创新性服务。',
      }, {
        img: chooceUsPic3,
        selectedImg: chooceUsPic3Selet,
        title: '服务创新',
        titleEnglish: 'Service innovation',
        content: '英迈在金融领域深耕十数年，因丰富的行业经验和专业咨询能力获得了新老客户的信赖。',
      }, {
        img: chooceUsPic4,
        selectedImg: chooceUsPic4Selet,
        title: '技术实力',
        titleEnglish: 'Technical strength',
        content: '英迈以技术立身，投入大量研发资源，紧跟互联网技术前沿， 已沉淀有完备的技术体系。',
      }, {
        img: chooceUsPic5,
        selectedImg: chooceUsPic5Selet,
        title: '敏捷开发',
        titleEnglish: 'Agile development',
        content: '英迈自创有敏捷开发体系，能够快速响应客户需求， 并能灵活应对需求变化。',
      }, {
        img: chooceUsPic1,
        selectedImg: chooceUsPic1Selet,
        title: '王牌专业',
        titleEnglish: 'Ace professional',
        content: '英迈除了输出自身的互联网产品外，还输出互联网技术支持， 帮助客户植入互联网基因。',
      }],
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.animation.bind(this), this.state.time);
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  animation() {
    const { idx, data } = this.state;
    let newIdx = idx + 1;
    this.move();
    this.setState({ idx: newIdx });
    setTimeout(() => {
      data.splice(0, 1);
      data.push(data[0]);
      this.setState({
        data,
        idx: 3,
      });
    }, 1000);

  }

  move() {
    const domList = this.getClassDom();
    domList.card1.style.left = '42.25rem';

    domList.card2.style.left = '1.25rem';
    domList.card2.style.transform = 'scale(0.85, 0.85)';

    domList.card3.style.left = '21.75rem';
    domList.card3.style.opacity = '0.9';

    domList.card4.style.left = '42.25rem';
    domList.card4.style.opacity = '1';

    domList.card5.style.left = '62.75rem';
    domList.card5.style.transform = 'none';

    domList.card6.style.left = '83.25rem';
  }

  getClassDom() {
    const dom = {};
    for (let i = 1; i <= 6; i++) {
      const name = style[`card_list_${i}`];
      const card = document.getElementsByClassName(name)[0];
      dom[`card${i}`] = card;
    }
    return dom;
  }


  render() {
    const { idx, data } = this.state;
    return (
      <div className={style['home_chooce_us']}>
        <div className={style['home_chooce_us_header']}>
          <h2>Why chooce us</h2>
          <h3>为何选择我们</h3>
          <img src={titleIcon2} alt="标题" />
          <p>英迈成立自2010年，<br />
            为行业领先的场景化数字解决方案提供商。
          </p>
        </div>
        <div className={style['home_chooce_us_content']}>
          <article style={{ marginLeft: 0 }} id="frame">
            {data.map((item, index) => {
              const isSelected = index === idx - 1;
              const className = `card_list_${index + 1}`;
              return (
                <section key={`${item.titleEnglish}_${index}`}
                  className={isSelected ?
                    `${style[className]} ${style['selected_card']}` :
                    `${style[className]}`}>
                  <div className={style['card_header']}>
                    <img src={isSelected ? item.selectedImg : item.img} alt={item.title} />
                    <p>
                      {item.title}<br />
                      <span>{item.titleEnglish}</span>
                    </p>
                  </div>
                  <div className={style['card_centent']}>
                    <div className={style['card_title']}>
                      <i></i>
                      <h3>
                        {item.title}<br />
                        <span>{item.titleEnglish}</span>
                      </h3>
                      <i></i>
                    </div>
                    <p>{item.content}</p>
                  </div>
                </section>
              )
            })}
          </article>
        </div>
      </div>
    );
  }
}

// 资产认证
class Qualification extends React.Component {
  render() {
    return (
      <div className={style['home_businese']}>
        <div className={style['home_businese_header']}>
          <h2>Qualification certification</h2>
          <h3>资产认证</h3>
          <img src={titleIcon} alt="标题" />
          <p>
            为行业提供持续的增长价值。
          </p>
        </div>
        <div className={`${style['home_qulification']} clearfix`}>
          <div className={`${style['home_qulification_left']}`}>
            <ul>
              <li><img src={dagou} /> 华为解决方案开发伙伴认证</li>
              <li><img src={dagou} /> CMMI 3软件能力成熟度模型集成认证</li>
              <li><img src={dagou} /> ISO9001质量管理体系认证</li>
              <li><img src={dagou} /> 高新技术企业认证</li>
              <li><img src={dagou} /> 软件企业认证</li>
              <li><img src={dagou} /> 计算机软件著作权登记证书(50+项)</li>
            </ul>
          </div>
          <div>
            <img className={`${style['home_qulification_right1']}`} src={zhengshu1} alt="标题" />
            <img className={`${style['home_qulification_right2']}`} src={zhengshu2} alt="标题" />
            <img className={`${style['home_qulification_right3']}`} src={zhengshu3} alt="标题" />
          </div>
        </div>
      </div>
    )
  }
}

// 合作伙伴
class Partners extends React.Component {
  render() {
    return (
      <div className={style['home_partners']}>
        <div className={style['home_partners_title']}>
          <h2>Partners</h2>
          <h3>合作伙伴</h3>
          <img src={titleIcon} alt="标题" />
          <span></span>
        </div>
        <article className="clearfix">
          <section>
            <img src={partners1} alt="中国移动" />
          </section>
          <section>
            <img src={partners2} alt="商业银行" />
          </section>
          <section>
            <img src={partners3} alt="城镇银行" />
          </section>
          <section>
            <img src={partners4} alt="新中新集团" />
          </section>
        </article>
        <article className="clearfix">
          <section>
            <img src={partners5} alt="中国移动" />
          </section>
          <section>
            <img src={partners6} alt="商业银行" />
          </section>
          <section>
            <img src={partners7} alt="城镇银行" />
          </section>
          <section>
            <img src={partners8} alt="新中新集团" />
          </section>
        </article>
        <article className="clearfix">
          <section>
            <img src={partners9} alt="中国移动" />
          </section>
          <section>
            <img src={partners10} alt="商业银行" />
          </section>
          <section>
            <img src={partners11} alt="城镇银行" />
          </section>
          <section>
            <img src={partners12} alt="新中新集团" />
          </section>
        </article>
      </div>
    );
  }
}

// 联系我们
class ContactUs extends React.Component {
  render() {
    return (
      <div className={style['home_contact_us']}>
        <address>
          <h2>Contact us</h2>
          <h3>联系我们</h3>
          <img style={{ width: '1.5rem' }} src={contactUsIcon} alt="联系我们" />
          <div style={{ margin: '3rem 0 1.25rem 0' }}>
            <img style={{ width: '1.875rem' }} src={contactUsPhone} alt="电话" />
          </div>
          <p style={{
            fontSize: '1.5rem',
            fontWeight: 400,
            color: '#424242',
            margin: '1.6rem 0 1.375rem 0'
          }}>
            0591-87880510
          </p>
          <p style={{
            fontSize: '1.5rem',
            fontWeight: 400,
            color: '#424242',
            margin: '1.6rem 0 1.375rem 0'
          }}>
            王女士 15959086845
          </p>
          <p style={{ fontSize: '0.875rem', color: '#666' }}>
            （ 周一至周五 8:30-17:30 ）
          </p>
          <img
            style={{ margin: '2.06rem 0 1.25rem 0', width: '1.875rem' }}
            src={contactUsEmail}
            alt="邮箱" />
          <p style={{ fontSize: '1.25rem', color: '#424242', lineHeight: '1.25rem' }}>
            emsoft@emsoft.com.cn
          </p>
          <img
            style={{ margin: '2.125rem 0 1.25rem 0', width: '1.5rem' }}
            src={contactUsAddrss}
            alt="地址" />
          <p style={{ color: '#424242', fontSize: '1.25rem' }}>
            总部地址：福建省福州市鼓楼区乌山西路318号鼓楼科技大厦4层
          </p>
        </address>
      </div>
    );
  }
}
