/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';

const icon = {
  white: require('@assets/img/about/title_icon_2.png'),
  blue: require('@assets/img/about/title_icon_1.png')
}

/**
 * @extends React.Component
 * @param {String} props.title                    标题
 * @param {String} props.subTItle                 子标题
 * @param {String || reactElement} props.content  内容
 * @param {Object} props.titleStyle               标题覆盖样式 
 * @param {Object} props.subTitleStyle            子标题覆盖样式
 * @param {Object} props.contentStyle             内容覆盖样式
 * @param {Boolean} props.showBorder              是否显示 border
 * @param {String} props.icon                     图标（ white 、blue ）
 */
class TitleBlock extends React.Component{
  render(){
    return (
      <div className={style['title-block']}>
        <div 
          style={this.props.subTitleStyle}
          className={style['title-block-sub-title']}>
          {this.props.subTitle}
        </div>
        <div 
          style={this.props.titleStyle}
          className={style['title-block-title']}>
          {this.props.title}
        </div>
        <div 
          className={style['title-block-icon']}
          style={this.props.showBorder ? {} : { border: 'none' }}>
          <img src={icon[this.props.icon]} alt="图标"/>
        </div>
        {
          this.props.content ? 
            <div className={style['title-block-content']} style={this.props.contentStyle}>
              {this.props.content}
            </div> : null
        }
      </div>
    );
  }
}
export default TitleBlock;
