/**
 * 产品详情页面
 *
 * @author JFJ
 */
import React from 'react';

export default class ProductDetails extends React.Component {

  get type() {
    return this.props.match.params.type;
  }

  componentWillMount() {
    // 回到顶部
    document.documentElement.scrollTop = 0;
  }

  getProductList = (type) => ({
    eduCloud: [  // 教育云平台
      require('@assets/img/productListImg/eduCloud/banner.png'),
      require('@assets/img/productListImg/eduCloud/mobile_payment.png'),
      require('@assets/img/productListImg/eduCloud/function_introduction.png'),
      require('@assets/img/productListImg/eduCloud/practical_case.png'),
    ],
    campusCard: [],  // 校园一卡通
    biddingFund: [],  // 招标资金监管系统
    paymentColud: [],  // 代扣代缴云平台
    liveBuilding: [  // 住建资金监管云平台
      require('@assets/img/productListImg/liveBuilding/banner.png'),
      require(`@assets/img/productListImg/liveBuilding/all_function_introduction.png`),
      require(`@assets/img/productListImg/liveBuilding/presale_supervision_system.png`),
      require(`@assets/img/productListImg/liveBuilding/introduction_for_supervision.png`),
      require('@assets/img/productListImg/liveBuilding/maintenance_system.png'),
      require(`@assets/img/productListImg/liveBuilding/introduction_for_maintenance.png`),
      require(`@assets/img/productListImg/liveBuilding/stock_supervision_system.png`),
      require('@assets/img/productListImg/liveBuilding/platform_feature.png'),
      require('@assets/img/productListImg/liveBuilding/benefit analysis.png'),
    ],
    financialServiceForPrisoner: [ // 服刑人员金融服务平台
      require('@assets/img/productListImg/financialServiceForPrisoner/banner.png'),
      require('@assets/img/productListImg/financialServiceForPrisoner/resolve_case.png'),
      require('@assets/img/productListImg/financialServiceForPrisoner/function_introduction.png'),
      require('@assets/img/productListImg/financialServiceForPrisoner/system_advantages.png'),
      require('@assets/img/productListImg/financialServiceForPrisoner/practical_case.png'),
    ],
    smartPrison: [//服刑人员综合服务一体化平台
      require('@assets/img/productListImg/smartPrison/bn_banner.png'),
      require('@assets/img/productListImg/smartPrison/bg_introduction.png'),
      require('@assets/img/productListImg/smartPrison/bg_lightspot.png'),
      require('@assets/img/productListImg/smartPrison/bg_worth.png'),
      require('@assets/img/productListImg/smartPrison/bg_huawei.png'),
    ],
    smartProperty: [//智慧资产数字化管理平台
      require('@assets/img/productListImg/smartProperty/bn_banner.png'),
      require('@assets/img/productListImg/smartProperty/bg_introduction.png'),
      require('@assets/img/productListImg/smartProperty/bg_lightspot.png'),
      require('@assets/img/productListImg/smartProperty/bg_speciality.png'),
    ],
    moneyInvolved: [//涉案款项监管与智慧研判平台
      require('@assets/img/productListImg/moneyInvolved/bn_banner.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_introduction.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_painspot.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_flow.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_multiport.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_judge.png'),
      require('@assets/img/productListImg/moneyInvolved/bg_worth.png'),
    ],
    bankRiskConctrol: [//银行运营风控
      require('@assets/img/productListImg/bankRiskConctrol/bn_banner.png'),
      require('@assets/img/productListImg/bankRiskConctrol/bg_introduction.png'),
      require('@assets/img/productListImg/bankRiskConctrol/bg_lightspot.png'),
      require('@assets/img/productListImg/bankRiskConctrol/bg_frame.png'),
    ],
  }[type]);

  loadCurrProduct() {
    return this.getProductList(this.type);
  }

  renderCurrProduct() {
    return this.loadCurrProduct().map(imgUrl => (
      <img
        alt=""
        src={imgUrl}
        key={imgUrl}
        style={{ width: '100%' }} />));
  }

  render() {
    return this.renderCurrProduct();
  }
}

