/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';

/**
 * 可修改数据
 * @constant
 */
const data = {
  title: {
    subTitle: 'Technical ability',
    title: '技术能力',
    content: (
      <Fragment>
        <p>产品线已经涵盖了教育行业、互联网+金融、互联网+政务、大数据等多领域，</p>
        <p>成长为一家有核心竞争力的综合性软件公司。</p>
      </Fragment>
    ),
    titleStyle: {color: '#fff'},
    subTitleStyle: {color: '#fff'},
    contentStyle: {color: '#fff', opacity: 0.6},
    icon: 'white',
  },
  list: [
    {
      title: '紧跟技术前沿',
      subTitle: '勇于学习和使用新技术新框架，微服务架构、人工智能……'
    },{
      title: '投入大量研发力量',
      subTitle: '我们投入大量人力、物力在研发团队研究技术模型和业务模型'
    },{
      title: '技术沉淀',
      subTitle: '17年，我们在技术研发上孜孜不倦，已形成完备的技术体系'
    }
  ]
};

class TechnicalSkills extends React.Component{
  render(){
    return (
      <div className={style['technical']}>
        <div className={style['technical-wrapper']}>
          <TitleBlock {...data.title}/>
          <ListBlock />
        </div>
      </div>
    );
  }
}
export default TechnicalSkills;

class ListBlock extends React.Component{
  render(){
    return (
      <div className="clearfix">
        <ul className={`${style['technical-list']} fr`}>
          {
            data.list.map( v => (
              <li key={v.title} className={style['technical-list-item']}>
                <div className={style['technical-list-title']}>
                  {v.title}
                </div>
                <div className={style['technical-list-sub-title']}>
                  {v.subTitle}
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    );  
  }
}
