/**
 * @author LH
 */
import React, { Fragment } from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import Swiper from 'swiper/dist/idangerous.swiper.min.js'
import swiperImg from '@assets/img/layout/swiper.jpg';
import swiperBg from '@assets/img/layout/swiper-bg.png';
import style from '../../../index.module.scss';
import 'swiper/dist/idangerous.swiper.css'
import './swiper.scss';

/**
 * 隐藏 pagination 条件（路由列表）， 在这些路由中页面不显示 pagination（小点点）
 * @constant
 * @type {Array}
 */
const HIDDEN_PAGINATION_CONDS = [];

/**
 * 隐藏路由列表（在下列列表中隐藏swiper）
 * @constant
 * @type {Array}
 */
const HIDDEN_ROUTE = [
  '/product/details/*',
  '/about',
  '/product',
  '/recruitment',
  '/contact'
];

/**
 * 轮播图片
 * @constant
 * @type {Array}
 */
const banners = [
  { img: require('@assets/img/banner/banner_1.jpg') },
  { img: require('@assets/img/banner/banner_2.jpg') },
  { img: require('@assets/img/banner/banner_3.jpg') },
  { img: require('@assets/img/banner/banner_4.jpg') },
  { img: require('@assets/img/banner/banner_5.jpg') },
];

/**
 * 每个轮播项在这里
 * @extends React.Component
 */
class SwiperSlide extends React.Component {
  render() {
    return (
      <Fragment>
        {
          banners.map((v, index) => (
            <div key={index} className="swiper-slide">
              <img src={v.img} alt="轮播项" />
            </div>
          ))
        }
      </Fragment>
    );
  }
}

@withRouter
class SwiperBlock extends React.Component {

  componentDidMount() {
    this.createSwiper();
  }

  // 创建轮播
  createSwiper = () => {
    const config = {
      loop: true,                 //可选选项，开启循环
      autoplay: 5000,             //可选选项，自动滑动
      grabCursor: true,
      calculateHeight: true,
      pagination: '.pagination',
      paginationClickable: true,
      autoplayDisableOnInteraction: false,
      observer: true,                         //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
      observeParents: true                    //修改swiper的父元素时，自动初始化swiper
    };
    this.mySwiper = new Swiper('.swiper-container', config);
  }

  /**
   * 路由匹配
   * @param {Array} 路由列表
   * @return {Boolean}  是否存在匹配项
   */
  matchRoute = (list) => {
    const { pathname } = this.props.location;
    const filter = list.filter(v => (
      !!matchPath(pathname, {
        path: v,
        exact: true,
        strict: false
      })
    ));
    return !!filter[0];
  }

  render() {
    return (
      <div className={`${style['swiper']} swiper`} style={this.hiddenStyle}>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <SwiperSlide />
          </div>
        </div>
        <div className="pagination-block">
          <div className="pagination-wrapper">
            <div>
              <img src={swiperBg} alt="" />
            </div>
            <div className={this.paginationClassName}>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 计算 pagination className
  get paginationClassName() {
    return `pagination ${this.matchRoute(HIDDEN_PAGINATION_CONDS) ? 'hidden' : 'show'}`;
  }

  // 是否隐藏
  get hiddenStyle() {
    const hiden = this.matchRoute(HIDDEN_ROUTE);
    return (hiden ? { height: 0, overflow: 'hidden' } : {});
  }
}

export default SwiperBlock;
