/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';

/**
 * 可修改数据
 * @constant
 */
const data = {
  title: {
    subTitle: 'Product development',
    title: '产品发展',
    content: (
      <Fragment>
        <p>产品线已经涵盖了教育行业、互联网+金融、互联网+政务、大数据等多领域，</p>
        <p>成长为一家有核心竞争力的综合性软件公司。</p>
      </Fragment>
    ),
    icon: 'blue',
  },
  list: [
    {
      time: '2005',
      title: '资金监管',
      content: `
        英迈17年来深耕于金融领域，针对政府及企事业单位（监管巨额资金和现金往来）
        通过银行的中间业务、银企直连实现与政府企事业单位无缝对接，
        为政府企事业单位提供端到端的自动化信息化的金融服务 
      `
    }, {
      time: '2009',
      title: '行业解决方案',
      content: `
        英迈在金融领域的服务历程中，逐步形成并输出体系化的行业解决方案，
        如住建行业、教育行业、医疗行业、政务行业等，能够提供长期的技术支持服务
      `
    }, {
      time: '2017',
      title: '拥抱互联网',
      content: `
        英迈紧跟技术前沿，从传统软件提供商向互联网企业转型，一是自主研发互联网产品，
        二是以互联网+行业+应用场景的技术服务帮助客户拥抱互联网
      `
    }
  ],
};

class ProductDevelopment extends React.Component{
  render(){
    return (
      <div className={style['development']}>
        <TitleBlock {...data.title}/>
        <Timeline />
        <div className={style['development-bg']}></div>
      </div>
    );
  }
}
export default ProductDevelopment;

class Timeline extends React.Component{
  render(){
    return (
      <div className={`${style['timeline']}`}>
        {
          data.list.map( v => (
            <div key={v.title} className={`${style['timeline-item']}`}>
              <div className={style['timeline-time']}>
                {v.time}
              </div>
              <h2 className={style['timeline-title']}>
                {v.title}
              </h2>
              <p className={style['timeline-content']}>
                {v.content} 
              </p>
            </div>
          ))
        }
      </div>
    );
  }
}
