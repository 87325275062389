/**
 * @author LH
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import style from '../../../index.module.scss';
import qrCode from '@assets/img/layout/qr-code.png';

/**
 * 显示侧边栏条件： 当滚动具体大于当前值时显示侧边栏
 * @constant
 * @type {Number}
 */
const SHOW_SIDE_LIST_CONDS = 700;

@withRouter
class SideBlock extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      show: false,
      showWithOpacity: false,
    };
  }

  componentDidMount(){
    window.addEventListener('scroll', this.debounce(this.onScroll, 50));
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScrol);
  }

  componentDidUpdate(preProps){
    this.monitorRoute(preProps);
  }

  /**
   * 监听路由变换: 当路由切换时回到顶部
   * @param {Object} preProps 上一个 props
   */
  monitorRoute = (preProps) => {
    const { match: oldMatch } =  preProps;
    const { match } =  this.props;
    if (oldMatch === match){return false;}
    this.setScrollTop(0);
  }

  /**
   * 防抖
   * @param {Function} func 事件函数
   * @param {Number} wait 时长
   */
  debounce = (func, wait) => {
    let timeout;
    return function () {
      let context = this;
      let args = arguments;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args)
      }, wait);
    }
  }

  /**
   * 监听滚动
   * @param {Object} e 事件
   */
  onScroll = (e) => {
    if (this.scrollTop > SHOW_SIDE_LIST_CONDS && !this.state.show ){
      this.setState({ show: true })
    } else if ( this.scrollTop < SHOW_SIDE_LIST_CONDS && this.state.show ){
      this.setState({ show: false })
    }
  }

  /**
   * 设置 scrollTop
   * @param {Number} value scrollTop值
   */
  setScrollTop(value){
    document.documentElement.scrollTop = value;
    window.pageYOffset = value;
    document.body.scrollTop = value;
  }

  // 滚动到顶部
  scrollToTop = () => {
    // speed: 速度（时间）, span 跨度(每次递减数值)
    const animate = (speed= 10, span = 100) => {
      setTimeout(() => {
        let pretreatment = this.scrollTop - span;
        pretreatment < 0 ? (pretreatment === 0) : animate(speed, span);
        this.setScrollTop(pretreatment);
      }, speed);
    }
    // （时间， 跨度）
    animate(10, 100);
  }

  // 鼠标移入（微信图标）
  onMouseEnter = () => {
    this.setState({ showWithOpacity: true });
  }

  // 鼠标移出（微信图标）
  onMouseLeave = () => {
    this.setState( { showWithOpacity: false } );
  }

  // 跳转首页
  jumpToHome = () => {
    this.props.history.push('/');
  }

  render(){
    return (
      <div className={this.sideBlockClassName}>
        <div className={this.blockQrClassName}>
          <div className={style['side-block-qr-wrapper']}>
            <img src={qrCode} alt="二维码"/>
          </div>
        </div>
        <div className={style['side-block-list']}>
          <div
            onClick={this.jumpToHome}
            className={`${style['side-block-list-home']} cp`}>
            <span className="iconfont iconzhuyeicon"></span>
          </div>
          <div
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            className={`${style['side-block-list-weixin']} cp`}>
            <span className="iconfont iconweixin"></span>
          </div>
          <div
            onClick={this.scrollToTop}
            className={`${style['side-block-list-arrow']} cp`}>
            <span className="iconfont iconxiangshang"></span>
          </div>
        </div>
      </div>
    );
  }

  // 获取 document.scrollTop
  get scrollTop(){
    return (
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop
    );
  }

  // 计算样式
  get blockQrClassName(){
    let className = style['side-block-qr'];
    this.state.showWithOpacity
      ? (className = `${className} show-o`)
      : (className = `${className} hidden-o`);
    return className;
  }

  // 计算样式
  get sideBlockClassName(){
    let className = style['side-block'];
    this.state.show
      ? (className = `${className} show-o`)
      : (className = `${className} hidden-o`);
    return className;
  }
}

export default SideBlock;
