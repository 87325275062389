import React, { Component } from 'react';
import Layout from './layout';
import '@assets/style/common.scss';
import '@assets/fontIcon/iconfont.css';
class App extends Component {
  componentDidMount() {
    const headItem = document.head;

    let oMeta = document.createElement('meta');

    oMeta.setAttribute('http-equiv','Content-Security-Policy');

    oMeta.content = 'upgrade-insecure-requests';

    headItem.appendChild(oMeta);
  }
  render() {
    return (
      <Layout />
    );
  }
}

export default App;
