import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import analyze from 'rgbaster';
import _ from 'lodash';

import './index.css';

/**
 * 基本公司产品的图片引入
 */
const imgList = [
  { 1: require('@assets/img/productimg/bn_1.png') },
  { 2: require('@assets/img/productimg/bn_2.png') },
  { 3: require('@assets/img/productimg/bn_3.png') },
  { 4: require('@assets/img/productimg/bn_4.png') },
  { 5: require('@assets/img/productimg/bn_5.png') },
  { 6: require('@assets/img/productimg/bn_6.png') },
];

/**
 * 取色值图片 防止二次加载
 */
const imgColorList = [
  { 1: require('@assets/img/productimg/bn_1.png') },
  { 2: require('@assets/img/productimg/bn_2.png') },
  { 3: require('@assets/img/productimg/bn_3.png') },
  { 4: require('@assets/img/productimg/bn_4.png') },
  { 5: require('@assets/img/productimg/bn_5.png') },
  { 6: require('@assets/img/productimg/bn_6.png') },
];

/**
 * 更多产品固定三种图片的引入
 */
const imgListMore = [
  { 7: require('@assets/img/productimg/7.png') }, //410
  { 8: require('@assets/img/productimg/9.png') }, //820
  { 9: require('@assets/img/productimg/10.png') }, //1230
];

/**
 * 包含更多产品的图片 3种
 */
const imgInfoMore = [
  {
    title: '更多产品',
    englishTitle: 'once more',
    img: imgListMore[0][7],
    classNames: ['maskh', 'secondImgStyle'],
    p: 'p7',
  },
  {
    title: '更多产品',
    englishTitle: 'once more',
    img: imgListMore[1][8],
    classNames: ['mask', 'firstImgStyle'],
    p: 'p7',
  },
  {
    title: '更多产品',
    englishTitle: 'once more',
    img: imgListMore[2][9],
    classNames: ['mask', 'thirdImgStyle'],
    p: 'p7',
  }
]
/**
 * 产品中心相关的数据信息
 */
const imgInfo = [
  {
    title: '服刑人员综合服务一体化平台',
    englishTitle: '提供金融、消费、教育等场景下的一站式自主服务',
    img: imgList[0][1],
    classNames: ['mask', 'firstImgStyle'],
    p: ',0.2',
    type: 'smartPrison',
  },
  {
    title: '智慧资产数字化管理平台',
    englishTitle: '智慧租控，数据统计，业财一体',
    img: imgList[1][2],
    classNames: ['maskh', 'secondImgStyle'],
    p: ',0.6',
    type: 'smartProperty',
  },
  {
    title: '专项资金监管云平台',
    englishTitle: '政府监管，专款专用',
    img: imgList[2][3],
    classNames: ['maskb', 'secondImgStyle'],
    p: ',1',
    type: 'liveBuilding',
  },
  {
    title: '涉案款项监管与智慧研判平台',
    englishTitle: '面对纪委、公检法等机关，构建涉案款项的全域监管闭环',
    img: imgList[3][4],
    classNames: ['mask', 'firstImgStyle'],
    p: ',0.2',
    color: '@assets/img/productimg/1.png',
    type: 'moneyInvolved',
  },
  {
    title: '教育缴费云平台',
    englishTitle: '“云平台+易支付+快对账”解决方案',
    img: imgList[4][5],
    classNames: ['maskh', 'secondImgStyle'],
    p: ',0.5',
    type: 'eduCloud',
  },
  {
    title: '银行运营风控',
    englishTitle: '构建数据治理体系，助力运营合规优化',
    img: imgList[5][6],
    classNames: ['maskh', 'secondImgStyle'],
    p: ',0.7',
    type: 'bankRiskConctrol',
  }
]

class Product extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="product-header">
        <div className='product-tabbar' />
        <div className="product-conten-top">
          <ul>
            <li className="lione">Product center</li>
            <li className="litwo">产品中心</li>
            <li className="lithree"><span></span></li>
            <li className="lifour">
              <p>
                产品已经覆盖了教育行业、互联网+金融、互联网+政务、大数据等多领域
              </p>
              <p style={{ marginTop: '5px' }}>
                成为了一家有核心竞争力的综合性软件公司。
              </p>
            </li>
          </ul>
        </div>
        <ImgBlock
          normalInfo={imgInfo}
        />
        <div className="product-conten-bottom">
          <p>
            到底了没有更多了~
          </p>
        </div>
      </div>
    );
  }
}

export default Product;

class ImgBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      curryulwidth: 0,
      imgmark: 0,
      normalInfo: this.props.normalInfo,
      status: 0,
      arrColors: [],
    }
  }

  imgWidth = () => {
    const box = document.getElementsByClassName('computedWidth');
    const img1Width = box && box[0] && box[0].clientWidth;
    const widths = imgList.reduce((total, item, i) => {
      return total + (box[i] && box[i].clientWidth);
    }, 0);
    this.setState({ count: widths });
    this.setState({ imgmark: img1Width });
  }

  /**
   * 拿到当前块得宽度
   * */
  ulWidth = () => {
    const ulBox = document.getElementsByClassName('MiddleUl');
    const widths = ulBox && ulBox[0] && ulBox[0].clientWidth;
    this.setState({ curryulwidth: widths });
  }


  /**
 * 根据图片最深颜色判断区块的背景阴影
 */

  async boxShadowColors(states) {
    let arrColor = [];
    for (let i = 0; i < imgColorList.length; i++) {
      const res = await analyze(imgColorList[i][i + 1],
        { ignore: ['rgb(255,255,255)', 'rgb(0,0,0)'] }, { scale: 0.3 });
      arrColor.push(res[0].color);
      console.log(res[0].color);
      if (arrColor.length === imgColorList.length) {
        this.setState({ [states]: arrColor });
      }
    }
  }

  componentDidMount() {
    this.boxShadowColors('arrColors');
    setTimeout(this.imgWidth, 10);
    setTimeout(this.ulWidth, 10);
  }

  /**
   * 给rgb加透明度
   */
  opacityRgb(str, chara, charb, replaceChara, replaceCharb) {
    if (typeof (str) === 'string') {
      let arr = str.split('');
      const lastIndexDress = arr.lastIndexOf(chara);
      const firstIndexDree = arr.lastIndexOf(charb);
      arr.splice(lastIndexDress, 0, replaceChara);
      arr.splice(firstIndexDree, 0, replaceCharb);
      str = arr.join('');
      return str;
    }
  }
  /**
   * 添加boxshadow样式 改变 1 5 的样式为 4
   */
  boxShadowFn = (arrColors, index, itemp) => {
    const rgbOpt = this.opacityRgb(arrColors[index], ')', '(', itemp, 'a');
    // if (index === 5) {
    //   const opt = this.opacityRgb(arrColors[4], ')', '(', itemp, 'a');
    //   const str = opt + '0px 0px 15px 5px';
    //   return str;
    // } else {
    const str = rgbOpt + '0px 0px 15px 5px';
    return str;
    // }
  }

  markOneRender = () => {
    const { normalInfo, count, curryulwidth, imgmark, mark } = this.state;
    const free = count
      && (Math.floor((count + (imgList.length - 1)) / curryulwidth + 1))
      * curryulwidth - count + imgList.length - 1;
    if (!mark && free && free < imgmark) {
      if (normalInfo.length < imgList.length + 1) {
        normalInfo.push(imgInfoMore[0])
      }
      const arr = _.uniq(normalInfo)
      if (arr) {
        this.setState({ normalInfo: arr })
      }
      this.setState({ mark: true });
    } else if (!mark && curryulwidth > free && free > imgmark) {
      if (normalInfo.length < imgList.length + 1) {
        normalInfo.push(imgInfoMore[1])
      }
      const arr = _.uniq(normalInfo)
      if (arr) {
        this.setState({ normalInfo: arr })
      }
      this.setState({ mark: true });
    } else if (!this.mark && free && free > curryulwidth) {
      if (normalInfo.length < imgList.length + 1) {
        normalInfo.push(imgInfoMore[2])
      }
      if (arr) {
        this.setState({ normalInfo: arr })
      }
      this.setState({ mark: true });
    }
  }

  render() {
    const { normalInfo, arrColors, count, curryulwidth, imgmark } = this.state;
    const boxShadowFn = this.boxShadowFn;
    count && curryulwidth && imgmark && this.markOneRender();
    return (
      <div className="product-conten-middle">
        <ul className="MiddleUl clearfix">
          <li>
            {normalInfo && normalInfo.map((item, index) => {
              if (index <= imgList.length - 1) {
                return (
                  <div className="computedWidth fl"
                    key={(new Date).getTime() + Math.random() + item.title}>
                    <p key={(new Date).getTime() + Math.random() + item.title}
                      style={{
                        boxShadow: `${arrColors && arrColors[index]
                          && boxShadowFn(arrColors, index, item.p)}`
                      }}
                    >
                      <Link to={`/product/details/${item.type}`}>
                        <img className={item.classNames[1]} src={item.img} alt="" />
                      </Link>
                      <span className={item.classNames[0]}>
                        <i className="masklay">
                          <i className="showTop"></i>
                          <i className="showTitle">{item.title}</i>
                          <i className="showEnglishTitle">{item.englishTitle}</i>
                          <i className="showBottom"></i>
                        </i>
                      </span>
                    </p>
                  </div>
                );
              } else {
                return (
                  <div className="computedWidth fl"
                    key={(new Date).getTime() + Math.random()}>
                    <p className={item.p} key={(new Date).getTime() + Math.random()}>
                      <img className={item.classNames[1]} src={item.img} alt="" />
                    </p>
                  </div>
                );
              }
            })
            }
          </li>
        </ul>
      </div>
    );
  }
}
