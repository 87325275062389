/**
 * @author LH
 */
import React from 'react';
import style from '../../../index.module.scss';

/**
 * 列表数据
 * @constant
 * @type {Array}
 * - title: 标题
 * - subTitle: 子标题
 * - img: 图片
 */
const list = [
  {
    title: '青春与激情',
    subTitle: '我们年轻但不幼稚',
    iconName: 'iconqingchunjiqing',
    style: {
      fontSize: '2.625rem',
      fontWeight: 600,
      color: '#51a2ff',
      position: 'relative',
      top: '0.8125rem',
    },
  }, {
    title: '灵活',
    subTitle: '说干就干、快速精准',
    iconName: 'iconlinghuo',
    style: {
      fontSize: '3rem',
      color: '#51a2ff',
      position: 'relative',
      top: '0.8rem',
    },
  }, {
    title: '敏捷开发',
    subTitle: '快速响应用户需求',
    iconName: 'iconminjiekaifa',
    style: {
      fontSize: '4.8rem',
      color: '#51a2ff',
      position: 'relative',
      top: '1rem',
    },
  }, {
    title: '专注',
    subTitle: '深耕与金融领域',
    iconName: 'iconzhuanzhu1',
    style: {
      fontSize: '2.625rem',
      color: '#51a2ff',
      position: 'relative',
      top: '0.8125rem',
    },
  }, {
    title: '技术分享',
    subTitle: '大咖助力团队进步',
    iconName: 'iconjishufenxiang',
    style: {
      fontSize: '3.75rem',
      color: '#51a2ff',
      position: 'relative',
      top: '0.8125rem',
    },
  },
];

export default class IconList extends React.Component{
  render(){
    return (
      <div className={style['icon-list']}>
        <div className={style['icon-list-wrapper']}>
          <div className={style['icon-list-body']}>
            {
              list.map((v, index) => (
                <IconListItem { ...v} key={index}/>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

class IconListItem extends React.Component{
  static defaultProps = {
    title: '标题',
    subTitle: '子标题',
    img: '图标'
  };
  render(){
    return (
      <div className={style['icon-list-item']}>
        <div className={style['icon-list-item-img']}>
          <span
            className={`iconfont ${this.props.iconName}`}
            style={this.props.style}></span>
        </div>
        <div className={style['icon-list-item-title']}>
          {this.props.title}
        </div>
        <div className={style['icon-list-item-sub-title']}>
          {this.props.subTitle}
        </div>
      </div>
    );
  }
}
