/**
 * @author LH
 */
import React from 'react';
import BusinessList from './subpage/BusinessList';
import ServiceIntroduction from './subpage/ServiceIntroduction';
import TechnicalSkills from './subpage/TechnicalSkills';
import ProductDevelopment from './subpage/ProductDevelopment';
import ProfessionalTeam from './subpage/ProfessionalTeam';
import TechnologyChangeService from './subpage/TechnologyChangeService';
import style from './index.module.scss';

class About extends React.Component {
  render() {
    return (
      <div className={style['about']}>
        <div className={style['technical-tabbar']} />
        <BusinessList />
        <ServiceIntroduction />
        <TechnicalSkills />
        <ProductDevelopment />
        <ProfessionalTeam />
        <TechnologyChangeService />
      </div>
    );
  }
}

export default About;
