/**
 * @author LH
 */
import React from 'react';
import RouterBlock from '../../route';
import SideBlock from './subpage/SideBlock';
import style from '../../index.module.scss';

export default class Content extends React.Component{
  render(){
    return (
      <div className={style['layout-content']}>
        <RouterBlock />
        <SideBlock />
      </div>
    );
  }
}
