/**
 * @author LH
 */
import React from 'react';
import IconList from './subpage/IconList';
import FooterInfo from './subpage/FooterInfo';
import style from '../../index.module.scss';

export default class Footer extends React.Component{
  render(){
    return (
      <div className={style['footer']}>
        <IconList />
        <FooterInfo />
      </div>
    );
  }
}
