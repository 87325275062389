/**
 * @author LH
 */
import React, { createRef } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import style from '../../../index.module.scss';
import logo from '@assets/img/layout/logo-menu.png'

/**
 * header 菜单栏配置信息：
 * @constant
 * @type {Array}
 * - title: 标题
 * - subTitle: 子标题
 * - paths: 匹配路由, 点击默认跳转至第一个路由, 依次和当前 pathname 进行匹配判断是否是当前页面的菜单栏
 * - home： 是否是首页(logo)
 */
const data = [
  {
    title: "关于英迈",
    subTitle: "about",
    paths: ['/about']
  }, {
    title: "产品中心",
    subTitle: "product",
    paths: ['/product', '/product/*']
  }, {
    home: true,
    paths: ['/']
  }, {
    title: "招贤纳士",
    subTitle: "recruitment",
    paths: ['/recruitment']
  }, {
    title: "联系我们",
    subTitle: "contact",
    paths: ['/contact']
  },
];

/**
 * 隐藏菜单栏条件
 * @constant
 * @type {Number}
 */
const HIDDEN_MENU_CONDS = 150;

@withRouter
class ItemBlock extends React.Component{
  static defaultProps = {
    paths: [],
    home: false,
    title: "关于英迈",
    subTitle: "about",
  }

  state = {className: `${style['item-block']} fl cp`};

  // 页面跳转： 默认跳转至第一个路由
  jumpTo = () => {
    const { history, paths } = this.props;
    paths[0] && history.push(paths[0]);
  }

  // 鼠标移入事件
  onMouseEnter = () => {
    this.props.onMouseEnter && this.props.onMouseEnter(this.props.index);
  }

  componentDidMount(){
    this.setCurrentIndex();
  }

  componentDidUpdate(preProps){
    if (preProps.match !== this.props.match){
      this.setCurrentIndex();
    }
  }

  // 设置当前 index
  setCurrentIndex = () => {
    let className = `${style['item-block']} fl cp`;
    const { paths,  location} = this.props;
    const { pathname } = location || {};
    if (pathname){
      const matchPathResult = paths.map( v => (
        matchPath(pathname, {
          path: v,
          exact: true,
          strict: false
        })
      ));
      if (matchPathResult.filter(v => !!v).length > 0){
        this.props.setCurrentIndex(this.props.index);
        className = `${className} ${style['item-block-current']}`;
      }
      this.setState({ className });
    }
  }

  render(){
    return (
      <div
        className={this.state.className}
        onMouseEnter={this.onMouseEnter}
        onClick={this.jumpTo}
      >
        {
          !this.props.home ?
            <div className={`${style['item-block-normal']}`} >
              <div className={style['item-block-normal-title']}>
                {this.props.title}
              </div>
              <div className={style['item-block-normal-sub-title']}>
                {this.props.subTitle}
              </div>
            </div> :
            <div className={`${style['item-block-home']}`}>
              <img src={logo} alt="logo"/>
            </div>
        }
      </div>
    );
  }
}

export default class MenuBlock extends React.Component{
  constructor(props){
    super(props);
    this.state = { hidden: false, followIndex: 2 };
    this.currentIndex = 2;
  }

  componentDidMount(){
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScrol);
  }

  /**
   * 监听滚动
   * @param {Object} e 事件
   */
  onScroll = (e) => {
    let hidden = false;
    if (this.scrollTop > HIDDEN_MENU_CONDS){
      hidden = this.historyScrollTop > this.scrollTop ? false : true;
    }
    this.state.hidden !== hidden && this.setState({ hidden });
    this.historyScrollTop = this.scrollTop;
  }

  // 设置当前 index
  setCurrentIndex = (index) => {
    this.currentIndex = index;
    this.setState({ followIndex: index });
  }

  // 鼠标停放
  onMouseEnter = (index) => {
    this.setState({ followIndex: index });
  }

  // 鼠标离开
  onMouseLeave = () => {
    this.setState({ followIndex: this.currentIndex });
  }

  render(){
    return (
      <div className={this.menuClassName}>
        <div
          onMouseLeave={this.onMouseLeave}
          className={`${style['menu-wrapper']} clearfix`}>
          {data.map( (v, index) => (
            <ItemBlock
              {...v}
              key ={index}
              index={index}
              onMouseEnter={this.onMouseEnter}
              setCurrentIndex={this.setCurrentIndex}
            />
          ))}
          <div className={`${style['follow']} ${style[`follow-${this.state.followIndex}`]}`}>
          </div>
        </div>
      </div>
    );
  }

  // 获取菜单 className
  get menuClassName(){
    return `${style['menu']} ${ this.state.hidden ? style['menu-hidden'] : ''}`;
  }

  // 获取 document.scrollTop
  get scrollTop(){
    return (
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop
    );
  }
}
