/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';
import imgBg from '@assets/img/about/change_bg_1.png';
/**
 * 可修改数据
 * @constant
 */
const data = {
  title: {
    subTitle: 'Technology change service',
    title: '技术改变服务',
    icon: 'blue',
  },
  
  list: [
    {
      icon: require('@assets/img/about/change_bg_1.png'),
      list: ['网上办事', '移动通讯', '自助服务'],
      width: '4em',
    }, {
      icon: require('@assets/img/about/change_bg_2.png'),
      list: ['人工智能', '移动支付', '地理位置'],
      width: '4em',
    }, {
      icon: require('@assets/img/about/change_bg_3.png'),
      list: ['云计算', '大数据', '物联网'],
      width: '3em',
    }
  ],
};

class TechnologyChangeService extends React.Component{
  render(){
    return (
      <div className={style['change']}>
        <TitleBlock {...data.title}/>
        <ListBlock/>
      </div>
    );
  }
}
export default TechnologyChangeService;

class ListBlock extends React.Component{
  render(){
    return(
      <div className={`${style['change-list']} clearfix`}>
        {
          data.list.map((v, index) => (
            <div key={index} className={`${style['change-list-item']} fl`}>
              <div className={style['change-list-icon']}>
                <img src={v.icon} alt="图标"/>
              </div>
              <ul>
                {v.list.map(ele => (
                  <li 
                    key={ele} 
                    style={{width: v.width}} 
                    className={style['change-list-li']}
                  >
                    {ele}
                  </li>
                ))}
              </ul>
            </div>
          ))
        }
      </div>
    );
  }
}
