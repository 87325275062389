/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';

/**
 * 可修改数据
 * @constant
 */
const data = {
  title: {
    subTitle: 'Service introduction',
    title: '服务介绍',
    icon: 'blue',
    showBorder: true,
  },
  // 图标列表
  iconList: [
    {
      icon: require('@assets/img/about/server_iocn_list_gz.png'),
      text: '公众'
    }, {
      icon: require('@assets/img/about/server_iocn_list_qy.png'),
      text: '企业'
    }, {
      icon: require('@assets/img/about/server_iocn_list_zfry.png'),
      text: '政府工作人员'
    }
  ],
  // 服务介绍
  server: {
    unite: {
      title: '统一服务', 
      className: 'service-row-yellow',
      data: [
        '网上办事', '移动支付', '协同办公', '自助服务', '效能监控', 
        '交易平台', '微信小程序', '代扣代缴', '流程设计',,'票据打印',
      ]
    },
    public: {
      title: '公共支撑', 
      className: 'service-row-red',
      data: [
        '人脸核身', '电子签证', '在线支付', '权限管控', '电子对账',
        '报表统计', '平台运维', '财务审计', '订单管理', '预警检测',
      ]
    },
    bigData: {
      title: '大数据服务',
      className: 'service-row-purple',
      data: [
        '数据采集', '数据清洗', '数据分析', '数据分享',
      ]
    },
    base: {
      title: '基础平台', 
      className: 'service-row-blue',
      data: [
        '云服务平台'
      ]
    },
  }
};

class ServiceIntroduction extends React.Component{
  render(){
    return (
      <div className={style['service-introduction']}>
        <TitleBlock {...data.title}/>
        <IconList />
        <ServiceBlock />
      </div>
    );
  }
}

export default ServiceIntroduction;

class IconList extends React.Component{
  render(){
    return (
      <div className={`${style['service-icon-list']} clearfix`}>
        { data.iconList.map( (v, index) => (
          <div 
            key={index}
            className={`${style['service-icon-list-item']} fl`}>
            <img src={v.icon} alt="图标"/>
            <p>{v.text}</p>
          </div>
        ))}
      </div>
    );
  }
}

/**
 * @param {String} props.title       标题
 * @param {String} props.className   基础类
 * @param {Object} props.children
 */
class RowBlock extends React.Component{
  render(){
    return (
      <div 
        className={`${style[`${this.props.className}`]} clearfix`}>
        <div className={`${style[`${this.props.className}-title`]} fl`}>
          {this.props.title}
        </div>
        <div className={`${style[`${this.props.className}-body`]} fl`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

class ServiceBlock extends React.Component{
  render(){
    return (
      <div className="service-block">
        <RowBlock {...data.server.unite}>
          <div className={`${style['service-block-five-item']} clearfix`}>
            {data.server.unite.data.map(v => (
              <div key={v} className={`${style['service-block-item']} fl`}>
                {v}
              </div>
            ))}
          </div>
        </RowBlock>
        <RowBlock {...data.server.public}>
          <div className={`${style['service-block-five-item']} clearfix`}>
            {data.server.public.data.map(v => (
              <div key={v} className={`${style['service-block-item']} fl`}>
                {v}
              </div>
            ))}
          </div>
        </RowBlock>
        <RowBlock {...data.server.bigData}>
          <div className={`${style['service-block-four-item']} clearfix`}>
            {data.server.bigData.data.map(v => (
              <div key={v} className={`${style['service-block-item']} fl`}>
                {v}
              </div>
            ))}
          </div>
        </RowBlock>
        <RowBlock {...data.server.base}>
          <div className={`${style['service-block-one-item']} clearfix`}>
            {data.server.base.data.map(v => (
              <div key={v} className={`${style['service-block-item']} fl`}>
                {v}
              </div>
            ))}
          </div>
        </RowBlock>
      </div>
    );
  }
}
