/**
 * @author LH
 */
import React, { Fragment } from 'react';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import Header from './subpage/Header/index';
import Footer from './subpage/Footer/index';
import Content from './subpage/Content/index';
import ProgressBar from './subpage/ProgressBar/index';
import style from './index.module.scss';
// import './index.scss';
export default class Layout extends React.Component{

  componentWillMount() {
    this.settingHtmlFontSize();
    window.onresize = () => {
      this.settingHtmlFontSize();
    }
  }

  settingHtmlFontSize() {
    const curScrollWidth = document.body.scrollWidth;
    let fontSize = curScrollWidth / 1920 * 16;
    if (fontSize < 12) { fontSize = 12; }
    const _html = document.getElementsByTagName('html')[0];
    _html.style.fontSize = `${fontSize}px`;
  }

  render(){
    return (
      <Router>
        <div className={style['layout']} style={{ minWidth: '1300px' }}>
          <ProgressBar />
          <Header />
          <Content />
          <Footer />
        </div>
      </Router>
    );
  }
}
