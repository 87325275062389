/**
 * @author LH
 */
import React, { Fragment } from 'react';
import style from '../index.module.scss';
import TitleBlock from './TitleBlock';

/**
 * team 列表 定时器时间
 * @constant
 */
const TEAM_TIME = 3000;

/**
 * 可修改数据
 * @constant
 */
const data = {
  title: {
    subTitle: 'Professional team',
    title: '专业团队',
    content: (
      <Fragment>
        <p>英迈拥有一支年轻、专业、创新的团队</p>
        <p>英迈秉承“创新、敏捷、协作”的技术核心理念，始终把人和技术作为发展的第一推动力，</p>
        <p>为员工创造技术分享、合作的平台，提倡员工和公司共同成长。</p>
      </Fragment>
    ),
    icon: 'blue',
  },
  teams: {
    // 列表行数
    row: 3,
    list: [
      {
        icons: [
          require('@assets/img/about/team_1.png'),
          require('@assets/img/about/team_6.png'),
          require('@assets/img/about/team_11.png'),
        ], 
        titles: [ '技术总监', '销售代表','Java 开发攻城狮' ]
      }, {
        icons: [
          require('@assets/img/about/team_2.png'),
          require('@assets/img/about/team_7.png'),
          require('@assets/img/about/team_12.png'),
        ], 
        titles: [ '产品经理', '财务专员', 'Nodejs 开发攻城狮' ]
      }, {
        icons: [
          require('@assets/img/about/team_3.png'),
          require('@assets/img/about/team_8.png'),
          require('@assets/img/about/team_13.png'),
        ], 
        titles: [ '项目经理', '售前/售后技术支持攻城狮', 'C 开发攻城狮' ]
      }, {
        icons: [
          require('@assets/img/about/team_4.png'),
          require('@assets/img/about/team_9.png'),
          require('@assets/img/about/team_14.png'),
        ], 
        titles: [ 'UI设计狮', '人事专员', 'Python 开发攻城狮' ]
      }, {
        icons: [
          require('@assets/img/about/team_5.png'),
          require('@assets/img/about/team_10.png'),
          require('@assets/img/about/team_15.png'),
        ], 
        titles: [ '项目总监', '行政助理', '前端攻城狮' ]
      }, 
    ]
  }
};

class ProfessionalTeam extends React.Component{
  render(){
    return (
      <div className={style['team']}>
        <TitleBlock {...data.title}/>
        <TeamList/>
      </div>
    );
  }
}
export default ProfessionalTeam;

class TeamList extends React.Component{

  state={
    index: 0
  };

  componentDidMount(){
    this.addSetInterval();
  }

  componentWillUnmount(){
    this.clearInterval();
  }

  // 添加定时器
  addSetInterval = () => {
    this.timer = setInterval(() => {
      this.setState((preState) => {
        const row = data.teams.row;
        const preIndex = preState.index;
        return { index: preIndex === (row - 1) ? 0 : (preIndex + 1)};
      });
    }, TEAM_TIME);
  }

  // 清除定时器
  clearInterval = () => {
    this.timer && clearInterval(this.timer);
  }

  /**
   * team 列表，指示器点击事件
   * @param {Number} index 索引
   */
  onClick = (index) => {
    this.setState(() => {
      return {index};
    });
  }

  /**
   * 获取className
   * @param {Number} index 索引
   */
  getClassName = (index) => {
    return (
      this.state.index === index 
        ? style['team-list-indication-current']
        : style['team-list-indication-item']
    );
  }

  render(){
    return (
      <div className={`${style['team-list']} clearfix`}>
        {
          data.teams.list.map( (v, index) => (
            <ItemBlock index={this.state.index} {...v} key={index} />
          ))
        }
        <ul className={style['team-list-indication']}>
          {
            data.teams.list[0].icons.map( (v, index) => (
              <li
                key={index} 
                onClick={this.onClick.bind(null, index)} 
                className={this.getClassName(index)}>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

class ItemBlock extends React.Component{
  static defaultProps = {
    icons: [],
    title: [],
  };

  /**
   * 获取 className
   * @param {Number} index 索引 
   * @param {String} type  类型（icon title）
   */
  getClassName = (index, type) => {
    let className = style[`team-${type}-item`];
    this.props.index === index 
      ? (className = `${className} ${style[`team-${type}-item-show`]}`)
      : (className = `${className} ${style[`team-${type}-item-hidden`]}`);
    return className;
  }

  render(){
    return (
      <div className={`${style['team-item']} fl`}>
        <div className={style['team-icon']}>
          {
            this.props.icons.map((v, index) => (
              <div key={index} className={this.getClassName(index, 'icon')}>
                <img src={v} alt="图标"/>
              </div>
            ))
          }
        </div>
        <div className={style['team-title']}>
          {
            this.props.titles.map((v, index) => (
              <div key={index} className={this.getClassName(index, 'title')}>
                {v}
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
