import Home from '@pages/Home';
import About from '@pages/About';
import Product from '@pages/Product';
import ProductDetails from '@pages/Product/ProductDetails';
import Contact from '@pages/Contact';
import Recruitment from '@pages/Recruitment';

export default [
  {
    name: '首页',
    path: '/',
    exact: true,
    component: Home,
  }, {
    name: '关于英迈',
    path: '/about',
    exact: true,
    component: About,
  }, {
    name: '产品中心',
    path: '/product',
    exact: true,
    component: Product,
  }, {
    name: '产品详情',
    path: '/product/details/:type',
    exact: true,
    component: ProductDetails,
  }, {
    name: '招贤纳士',
    path: '/recruitment',
    exact: true,
    component: Recruitment,
  }, {
    name: '联系我们',
    path: '/contact',
    exact: true,
    component: Contact,
  }
];
